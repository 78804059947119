import { Client } from './Client.js';
import { LocalConnection } from "./LocalConnection.js";
import { Server } from './Server.js';
import { WebsocketConnection } from "./WebsocketConnection.js";
import { RemoteConnection } from "./RemoteConnection.js";

let index = 0;

export class Application {
    Index = index++;
    #client = null;
    #server = null;
    #UpdateFrameBind = null;
    #UpdateTimerBind = null;
    #TimePrevious = -1;
    #ServerTimePrevious = 1e200;
    #LocalConnection = null;
    #Websocket = null;
    #UpdateFrameId = null;
    #UpdateTimerId = null;

    get Id() {
        const localId = localStorage.getItem('localId');
        return localId;
    }

    get IsHost() {
        return !!this.#server;
    }

    constructor() {
        this.#UpdateFrameBind = this.#UpdateFrame.bind(this);
        this.#UpdateTimerBind = this.#UpdateTimer.bind(this);
        this.#TimePrevious = performance.now() - 16;
    }

    Start(gameId = undefined) {
        this.#UpdateFrameId = requestAnimationFrame(this.#UpdateFrameBind);
        this.#UpdateTimerId = setTimeout(this.#UpdateTimerBind, 100);

        const localId = localStorage.getItem('localId') || this.NewId();
        localStorage.setItem('localId', localId);

        gameId = gameId ?? localStorage.getItem('gameId') ?? localId;
        localStorage.setItem('gameId', gameId);

        this.#Websocket = new WebsocketConnection();

        let clientConnection = null;
        if (localId === gameId) {
            this.#server = this.CreateServer(gameId);
            this.#server.Application = this;
            clientConnection = new LocalConnection();
            this.#Websocket.Listen(localId);
            this.#LocalConnection = clientConnection;
            const serverConnection = clientConnection._Other;
            this.#server.AddConnection(serverConnection);
        }
        else {
            this.#Websocket.Open(localId, gameId);
            const isClient = true;
            clientConnection = new RemoteConnection(this.#Websocket, isClient);
        }

        this.#client = this.CreateClient(clientConnection);
        this.#client.Application = this;
    }

    Clear() {
        localStorage.removeItem('localId');
        localStorage.removeItem('gameId');
    }

    Stop() {
        cancelAnimationFrame(this.#UpdateFrameId);
        clearTimeout(this.#UpdateTimerId);
        this.#UpdateFrameId = null;
        this.#UpdateTimerId = null;
        if (this.#server) {
            this.#server.Stop();
        }
        this.#client.Stop();
        this.#Websocket.Close();
        this.#Websocket = null;
        this.#client = null;
        this.#server = null;
    }

    NewId() {
        let result = '';
        const bitCount = 64;
        const nybbleCount = Math.floor((bitCount + 3) / 4);
        const chars = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'a', 'b', 'c', 'd', 'e', 'f'];
        for (let i = 0; i < nybbleCount; ++i) {
            const nybble = Math.min(Math.floor(Math.random() * 16), 15);
            let char = chars[nybble];
            result += char;
        }
        return result;
    }

    CreateClient(connection) {
        return new Client(connection);
    }

    CreateServer(id) {
        return new Server(id);
    }

    Update(time, dt) {
        if (this.#client) {
            this.#client.Update(time, dt);
        }
        if (this.#server) {
            let serverConnection = null;
            while (serverConnection = this.#Websocket.Accept()) {
                const remoteConnection = new RemoteConnection(serverConnection);
                this.#server.AddConnection(remoteConnection);
            }
            this.#server.Update(time, dt);
        }
        if (this.#LocalConnection) {
            this.#LocalConnection._Transmit();
        }
    }

    Pump() {
        const time = performance.now();
        const dt = time - this.#TimePrevious;
        if (dt > 40) {
            this.#Update(time);
        }
    }

    #Update(time) {
        const dt = time - this.#TimePrevious;
        // Animation frame and timer can fire at the same time. If that happens skip one.
        if (dt < 10) {
            return;
        }
        this.#TimePrevious = time;
        const serverTime = this.#Websocket.GetServerTime(time);
        if (serverTime === undefined) {
            return;
        }
        const dServerT = serverTime - this.#ServerTimePrevious;
        if (dServerT < 1) {
            if (dServerT < -1e10) {
                this.#ServerTimePrevious = serverTime;
            }
            return;
        }
        this.#ServerTimePrevious = serverTime;
        this.Update(serverTime, dServerT);
    }

    #UpdateFrame(time) {
        this.#UpdateFrameId = requestAnimationFrame(this.#UpdateFrameBind);
        this.#Update(time);
    }

    #UpdateTimer() {
        this.#UpdateTimerId = setTimeout(this.#UpdateTimerBind, 100);
        const time = performance.now();
        const dt = time - this.#TimePrevious;
        if (dt > 40) {
            this.#Update(time);
        }
    }
}
