import { Parent } from "./Parent.js";
import { SyncObject } from "../Engine/SyncObject.js";

export class Element extends Parent {
    #_Svg = null;
    #Room = null;
    #ElementId = null;
    #Visible = true;
    #OnClick = new Set();
    #OnPointerDown = new Set();
    #OnPointerUp = new Set();
    #OnPointerMove = new Set();
    #VisiblePrevious = null;
    #Disappeared = false; 
    #Appeared = false;
    static #DomToGameElement = new WeakMap();

    constructor() {
        super();
    }

    get Svg() {
        if (!this.#_Svg && this.Client && this.#Room && this.#ElementId) {
            const roomSvg = this.Room?.Svg;
            if (roomSvg) {
                const svg = roomSvg.getElementById(this.#ElementId);
                if (svg) {
                    this.#Svg = svg;
                }
            }
        }
        return this.#_Svg;
    }

    set #Svg(value) {
        if (this.#_Svg) {
            Element.#DomToGameElement.delete(this.#_Svg);
        }
        this.#_Svg = value;
        if (this.#_Svg) {
            Element.#DomToGameElement.set(this.#_Svg, this);
        }
    }

    get Room() {
        return this.#Room;
    }

    set RoomNext(value) {
        this._SendSetProperty('Room', value);
    }

    get ElementId() {
        return this.#ElementId;
    }

    set ElementIdNext(value) {
        this._SendSetProperty('ElementId', value);
    }

    get Name() {
        return this.#ElementId;
    }

    get Visible() {
        return this.#Visible;
    }

    set VisibleNext(value) {
        this._SendSetProperty('Visible', value);
    }

    get OnClick() {
        return this.#OnClick;
    }

    get OnPointerDown() {
        return this.#OnPointerDown;
    }

    get OnPointerUp() {
        return this.#OnPointerUp;
    }

    get OnPointerMove() {
        return this.#OnPointerMove;
    }

    get Appeared() {
        return this.#Appeared;
    }

    get Disappeared() {
        return this.#Disappeared;
    }

    Update(time, dt) {
        super.Update(time, dt);
        this.#UpdateSvg();

        if (this.#VisiblePrevious !== null) {
            const changed = this.#VisiblePrevious !== this.#Visible;
            this.#Appeared = changed && this.#Visible;
            this.#Disappeared = changed && !this.#Visible;
        }

        this.#VisiblePrevious = this.#Visible;
    }

    static FindElement(domElement) {
        return Element.#DomToGameElement.get(domElement);
    }

    _OnClick(event) {
        this.#EventHandler(event, this.#OnClick);
    }

    _OnPointerDown(event) {
        this.#EventHandler(event, this.#OnPointerDown);
    }

    _OnPointerUp(event) {
        this.#EventHandler(event, this.#OnPointerUp);
    }

    _OnPointerMove(event) {
        this.#EventHandler(event, this.#OnPointerMove);
    }

    _ReceiveSetProperty(name, value) {
        switch (name) {
            case 'Room':
                this.#Room = value;
                this.#Svg = null;
                break;
            case 'ElementId':
                this.#ElementId = value;
                this.#Svg = null;
                break;
            case 'Visible':
                this.#Visible = value;
                break;
            default:
                super._ReceiveSetProperty(name, value);
                break;
        }
    }

    #UpdateSvg() {
        if (this.Svg) {
            const styleVisible = this.Svg.style.display !== 'none';
            if (this.#Visible !== styleVisible) {
                this.Svg.style.display = this.#Visible ? 'inherit' : 'none';
            }
        }
    }

    #EventHandler(event, handlers) {
        for (const handler of handlers) {
            try {
                handler(event);
            }
            catch (err) {
                console.error(err);
            }
        }
    }
}

SyncObject.RegisterType(Element, 'Element');
