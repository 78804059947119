import { Child } from "./Child.js";
import { float2 } from "../Engine/float2.js";
import { SyncObject } from "../Engine/SyncObject.js";
import { Room } from "./Room.js";

export class Pin extends Child {
    #Room = null;
    #Body = null;
    #BodyPosition = new float2();
    #World = null;
    #WorldPosition = new float2();
    #MinRot = null;
    #MaxRot = null;
    #RotSpeed = null;
    #RotTorque = null;
    #Initialised = false;
    #Joint = null;

    constructor() {
        super();
    }

    get Initialised() {
        return this.#Initialised;
    }

    get BodyPosition() {
        return this.#BodyPosition.Clone();
    }

    get WorldPosition() {
        return this.#WorldPosition.Clone();
    }

    get MinRot() {
        return this.#MinRot;
    }

    get MaxRot() {
        return this.#MaxRot;
    }

    SetRotLimitsNext(minRot, maxRot) {
        this._SendSetProperty(
            'rotlimit',
            {
                minRot,
                maxRot,
            });
    }

    get RotSpeed() {
        return this.#RotSpeed;
    }

    set RotSpeedNext(value) {
        this._SendSetProperty('rotspeed', value);
    }

    get RotTorque() {
        return this.#RotTorque;
    }

    set RotTorqueNext(value) {
        this._SendSetProperty('rottorque', value);
    }

    InitialiseNext(room, body, bodyPos, world, worldPos, minRot, maxRot, rotSpeed, rotTorque) {
        bodyPos = { x: bodyPos.x, y: bodyPos.y };
        worldPos = { x: worldPos.x, y: worldPos.y };
        this._SendSetProperty(
            'InitPin',
            {
                room,
                body,
                bodyPos,
                world,
                worldPos,
                minRot,
                maxRot,
                rotSpeed,
                rotTorque
            });
    }

    Update(time, dt) {
        super.Update(time, dt);

        if (!this.Client) {
            if (!this.#Initialised) {
                this.#Initialise();
            }
            else {
                if (this.#MinRot === null || this.#MaxRot === null) {
                    this.#Joint.enableLimit(false);
                }
                else {
                    this.#Joint.enableLimit(true);
                    this.#Joint.setLimits(this.#MinRot, this.#MaxRot);
                }
                if (this.#RotSpeed === null || this.#RotTorque === null) {
                    this.#Joint.enableMotor(false);
                }
                else {
                    this.#Joint.enableMotor(true);
                    this.#Joint.setMotorSpeed(this.#RotSpeed);
                    this.#Joint.setMaxMotorTorque(this.#RotTorque);
                }
            }
        }
    }

    _ReceiveSetProperty(name, value) {
        switch (name) {
            case 'InitPin':
                this.#Room = value.room;
                this.#Body = value.body;
                this.#BodyPosition.Copy(value.bodyPos);
                this.#World = value.world;
                this.#WorldPosition.Copy(value.worldPos);
                this.#MinRot = value.minRot ?? null;
                this.#MaxRot = value.maxRot ?? null;
                this.#RotSpeed = value.rotSpeed ?? null;
                this.#RotTorque = value.rotTorque ?? null;
                break;
            case 'rotlimit':
                this.#MinRot = value.minRot ?? null;
                this.#MaxRot = value.maxRot ?? null;
                break;
            case 'rotspeed':
                this.#RotSpeed = value;
                break;
            case 'rottorque':
                this.#RotTorque = value;
                break;
            default:
                super._ReceiveSetProperty(name, value);
                break;
        }
    }

    #Initialise() {
        const ready
            = (this.#World || this.#Body)
            && (!this.#World || this.#World.Physics)
            && (!this.#Body || this.#Body.Physics);
        if (!this.Client && ready) {
            const world = this.#Room.World;
            const worldPos = planck.Vec2(this.WorldPosition.MultiplyScalar(1 / Room.pixelsPerMeter));
            const bodyPos = planck.Vec2(this.BodyPosition.MultiplyScalar(1 / Room.pixelsPerMeter));
            const lowerAngle = (this.MinRot ?? 0);
            const upperAngle = (this.MaxRot ?? 0);
            const motorSpeed = (this.RotSpeed ?? 0);
            const maxMotorTorque = this.RotTorque ?? 0;
            const enableLimit = this.MinRot !== null && this.MaxRot !== null;
            const enableMotor = this.RotSpeed !== null && this.RotTorque !== null;
            const bodyA = this.#World?.Physics ?? this.#Room?.WorldBody;
            const bodyB = this.#Body?.Physics ?? this.#Room?.WorldBody;
            const localAnchorA = worldPos;
            const localAnchorB = bodyPos;
            const jointDef = {
                enableLimit,
                lowerAngle,
                upperAngle,
                enableMotor,
                motorSpeed,
                maxMotorTorque,
                bodyA,
                localAnchorA,
                bodyB,
                localAnchorB,
            };
            const joint = planck.RevoluteJoint(jointDef);
            world.createJoint(joint);
            this.#Joint = joint;
            this.#Initialised = true;
        }
    }
}

SyncObject.RegisterType(Pin, 'Pin');
