export class Connection {
    #OnReceive = new Set();

    constructor() {
    }

    get Id() {
        throw "Connection base class get Id called";
    }

    get OnMessage() {
        return this.#OnReceive;
    }

    Send(data) {
        console.error('Connection.Send base class method called');
    }

    Update(time) {
    }

    _OnMessage(data) {
        for (const handler of this.OnMessage) {
            try {
                handler.call(this, data);
            }
            catch (err) {
                console.error(err);
            }
        }
    }
}
