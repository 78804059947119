import { float2 } from "../Engine/float2.js";
import { Body } from "./Body.js";
import { SyncObject } from "../Engine/SyncObject.js";
import { Room } from "./Room.js";
import { Pin } from "./Pin.js";
import { Button } from "./Button.js";
import { SevenSegment } from "./SevenSegment.js";
import { Element } from "./Element.js";
import { Glasses } from "./Glasses.js";
import { Torch } from "./Torch.js";
import { float2x3 } from "../Engine/float2x3.js";

export class Room01 extends Room {
    #Exit = null;
    #Exit1 = null;
    #Buttons = [];
    #ButtonSequence = '____';
    #SafeDoor = null;
    #HeldStatus = new Map();
    #Wheels = [];
    #Dials = [];
    #DialProxy1 = null;
    #DialProxy2 = null;
    #RivetTicks = new Map();
    #WheelState = new Map();
    #Jars = [];
    #Jar0Pin = null;
    #Plinths = [];
    #RopeJointDefs = [];
    #PrismaticJointDefs = [];
    #BalanceStrings = null;
    #BalanceAttaches = null;
    #BalanceHandles = null;

    constructor() {
        super();
        this._AddLayer('layer1', 32 / 31);
        this._AddLayer('layer2', 64 / 61);
        this._AddLayer('layer3', 128 / 121);
    }

    Update(time, dt) {
        super.Update(time, dt);

        while (this.#RopeJointDefs.length) {
            const front = this.#RopeJointDefs[0];
            const bodyA = front.bodyA.Physics;
            const bodyB = front.bodyB.Physics;
            if (!bodyA || !bodyB) {
                break;
            }
            front.bodyA = bodyA;
            front.bodyB = bodyB;
            const ropeJoint = planck.RopeJoint(front);
            this.World.createJoint(ropeJoint);
            this.#RopeJointDefs.splice(0, 1);
        }

        while (this.#PrismaticJointDefs.length) {
            const front = this.#PrismaticJointDefs[0];
            const bodyA = front.bodyA ? front.bodyA.Physics : this.WorldBody;
            const bodyB = front.bodyB.Physics;
            if (!bodyA || !bodyB) {
                break;
            }
            front.bodyA = bodyA;
            front.bodyB = bodyB;
            const joint = planck.PrismaticJoint(front);
            this.World.createJoint(joint);
            this.#PrismaticJointDefs.splice(0, 1);
        }

        if (!this.#Exit) {
            this.#Exit = this.FindDescendent('exit');
            if (this.#Exit) {
                if (this.Client) {
                    this.#Exit.OnClick.add(this.#ClientExitOnClick.bind(this));
                }
                else {
                    this.#Exit.OnClick.add(this.#ServerExitOnClick.bind(this));
                }
            }
        }

        if (!this.#Exit1) {
            this.#Exit1 = this.FindDescendent('exit1');
            if (this.#Exit1) {
                if (this.Client) {
                    this.#Exit1.OnClick.add(this.#ClientExit1OnClick.bind(this));
                }
                else {
                    this.#Exit1.OnClick.add(this.#ServerExitOnClick.bind(this));
                }
            }
        }

        const tickCount = 8;
        if (!this.Client) {
            for (const [body, held0] of this.#HeldStatus) {
                const held1 = !!body.HeldBy.size;
                if (held0 !== held1) {
                    if (held1) {
                        // Hold.
                        body.SetFilter(0x1);
                    }
                    else {
                        // Drop.
                        body.SetFilter(0x3);
                        if (body === this.#DialProxy1) {
                            const dialReal1 = this.#Dials[1];
                            const distance = dialReal1.PositionSmoothed.Subtract(body.PositionSmoothed).Length();
                            if (distance < 24) {
                                body.VisibleNext = false;
                                body.SetFilter(0x1);
                                dialReal1.VisibleNext = true;
                                this._PlaySound('itemattach');
                            }
                        }
                        else if (body === this.#DialProxy2) {
                            const dialReal2 = this.#Dials[2];
                            const distance = dialReal2.PositionSmoothed.Subtract(body.PositionSmoothed).Length();
                            if (distance < 24) {
                                body.VisibleNext = false;
                                body.SetFilter(0x1);
                                dialReal2.VisibleNext = true;
                                this._PlaySound('itemattach');
                            }
                        }
                    }
                    this.#HeldStatus.set(body, held1);
                }
            }

            let pressed = 0;

            if (this.#SafeDoor?.Visible) {
                for (const buttonData of this.#Buttons) {
                    if (buttonData.button.Pressed) {
                        if (buttonData.button.Held) {
                            this.#ButtonSequence = (this.#ButtonSequence + buttonData.sevenSegment.Character).slice(-4);
                            pressed += 1;
                            if (this.#ButtonSequence === '6413') {
                                this.#SafeDoor.VisibleNext = false;
                            }
                        }
                    }
                }
            }

            if (pressed) {
                const remaining = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
                for (const buttonData of this.#Buttons) {
                    const characterIndex = Math.floor(Math.random() * remaining.length);
                    const character = remaining[characterIndex];
                    remaining.splice(characterIndex, 1);
                    buttonData.sevenSegment.CharacterNext = character;
                    buttonData.sevenSegment.ColorSeedNext = Math.floor(Math.random() * 0x7FFFFFFF);
                }
            }

            for (const wheel of this.#Wheels) {
                const body = wheel.Physics;
                if (!body) {
                    continue;
                }
                const angle = body.getAngle();
                const spin = body.getAngularVelocity();
                const moment = body.getInertia();

                const rotations = angle / (2 * Math.PI);
                const ticks = rotations * tickCount;
                const nearestTick = Math.round(ticks);
                const toTick = nearestTick - ticks;
                const toAngle = (toTick / tickCount) * 2 * Math.PI;

                if (this.#RivetTicks.has(wheel)) {
                    const rivetTick = (nearestTick - Math.floor(nearestTick / tickCount) * tickCount) | 0;
                    this.#RivetTicks.get(wheel).tick = rivetTick;
                }

                const frequency = 10;
                const spring = moment * (frequency * frequency);
                const damping = 2 * Math.sqrt(spring * moment);

                const rawTorque = (damping * -spin + spring * toAngle);
                const maxTorque = moment * 2;
                const torque = Math.sign(rawTorque) * Math.min(Math.abs(rawTorque), maxTorque);
                body.applyTorque(torque);
            }

            const rivetSolution = [5, 4, 6, 1, 3, 0, 2];
            let rivetSolvedCount = 0;
            for (const { index, tick } of this.#RivetTicks.values()) {
                if (tick === rivetSolution[index]) {
                    rivetSolvedCount += 1;
                }
            }
            if (rivetSolvedCount >= rivetSolution.length) {
                const door1 = this.FindDescendent('door1');
                if (door1.Visible) {
                    door1.VisibleNext = false;
                }
            }

            if (this.#Dials?.[1]?.Visible === false) {
                if (this.#DialProxy1?.Visible === false
                    && this.#Dials?.[1]?.Visible === false) {
                    // Count number of plinths in the right place.
                    let plinthCount = 0;
                    for (let i = 2; i < Math.min(this.#Jars.length, this.#Plinths.length); ++i) {
                        const jar = this.#Jars[i];
                        const plinth = this.#Plinths[i];
                        if (jar.IsInContact(plinth)) {
                            plinthCount++;
                        }
                    }
                    const maxPlinthCount = 6;
                    if (plinthCount === maxPlinthCount) {
                        this.#DialProxy1.VisibleNext = true;
                        this.#Jar0Pin.SetRotLimitsNext(-5, 0);
                        this.#Jar0Pin.RotSpeedNext = -8;
                        this.#Jar0Pin.RotTorqueNext = 0.04;
                        this._PlaySound('jaropen')
                    }
                }
            }

            if (this.#DialProxy2?.IsInContact(this._balanceExitBody)) {
                this.#DialProxy2.SetFilter(3);
            }
        }
        else {
            // Client update.
            for (const wheel of this.Children) {
                if ((wheel instanceof Body)
                    && (wheel.Name.startsWith('wheel') || wheel.Name.startsWith('rivet'))) {
                    // Wheel update.
                    const angle = wheel.Rotation;
                    const rotations = angle / (Math.PI * 2);
                    const rotation = rotations - Math.floor(rotations);
                    const ticks = rotation * tickCount;
                    let tick = Math.round(ticks);
                    const toTick = tick - ticks;
                    tick = tick % tickCount;
                    if (!this.#WheelState.has(wheel)) {
                        this.#WheelState.set(wheel, { tickPrevious: tick });
                    }
                    else {
                        const wheelState = this.#WheelState.get(wheel);
                        const { tickPrevious } = wheelState;
                        if (tick !== tickPrevious) {
                            const distance = Math.abs(toTick);
                            if (distance < 0.1 || (((tick - tickPrevious) + 8) % 8) > 1) {
                                this._PlaySound('tick');
                                wheelState.tickPrevious = tick;
                            }
                        }
                    }
                }
                else if (wheel instanceof Button) {
                    if (wheel.Pressed) {
                        this._PlaySound('buttondown');
                    }
                    if (wheel.Released) {
                        this._PlaySound('buttonup');
                    }
                }
                else if (wheel instanceof Torch) {
                    if (wheel.PickedUp || wheel.Dropped) {
                        this._PlaySound('switch');
                    }
                    if (wheel.Hit > 1 && !wheel.Held) {
                        const volume = Math.min((wheel.Hit - 1) / 8, 1);
                        this._PlaySound('woodhit', volume);
                    }
                }
                else if (wheel instanceof Glasses) {
                    if (wheel.Hit > 1 && !wheel.Held) {
                        const volume = Math.min((wheel.Hit - 1) / 8, 1);
                        this._PlaySound('woodhit', volume);
                    }
                }
                else if (wheel.Name === 'door1') {
                    if (wheel.Disappeared) {
                        this._PlaySound('dooropen');
                    }
                }
                else if (wheel.Name === 'safedoor') {
                    if (wheel.Disappeared) {
                        this._PlaySound('safeopen');
                    }
                }
                else if (wheel.Name?.startsWith('ws')
                    || wheel.Name?.startsWith('proxywheel')
                    || wheel.Name === 'balance') {
                    if (wheel.Hit > 1 && !wheel.Held && wheel.Visible) {
                        const volume = Math.min((wheel.Hit - 1) / 8, 1);
                        this._PlaySound('woodhit', volume);
                    }
                }
                else if (wheel.Name?.startsWith('balanceHandle')) {
                    const speedDelta = Math.abs(wheel._SpeedPrevious - wheel.Speed);
                    if (speedDelta > 400 && wheel.Speed < 10 && !wheel.Held) {
                        const volume = Math.min((wheel.Speed - 400) / 1000, 1);
                        this._PlaySound('woodhit', volume);
                    }
                    wheel._SpeedPrevious = wheel.Speed;

                    {
                        const volume = Math.min(Math.max((wheel.Speed - 20) / 400, 0), 1);
                        if (volume > 0) {
                            if (!wheel._SlideSound) {
                                wheel._SlideSound = this._PlaySound('scrapeloop', 1);
                            }
                        }
                        if (wheel._SlideSound) {
                            wheel._SlideSound.Loop = true;
                            wheel._SlideSound.AnimateVolume(volume, 0.1);
                            if (wheel._SlideSound.Volume < 1e-4) {
                                wheel._SlideSound.Stop(0.01);
                                wheel._SlideSound = null;
                            }
                        }
                    }
                }
                else if (wheel.Name?.startsWith('balancePlunger')) {
                    {
                        const volume = Math.min(Math.max((wheel.Speed - 20) / 400, 0), 1);
                        if (volume > 0) {
                            if (!wheel._SlideSound) {
                                wheel._SlideSound = this._PlaySound('scrapeloop', 1);
                            }
                        }
                        if (wheel._SlideSound) {
                            wheel._SlideSound.Loop = true;
                            wheel._SlideSound.AnimateVolume(volume, 0.1);
                            if (wheel._SlideSound.Volume < 1e-4) {
                                wheel._SlideSound.Stop(0.01);
                                wheel._SlideSound = null;
                            }
                        }
                    }
                }
            }
            const lighting = document.getElementById('lighting');
            if (lighting) {
                lighting.style.pointerEvents = 'none'; // Ignore clicks.
            }

            if (!this.#BalanceStrings) {
                const string0 = this.Svg?.getElementById('balanceString0');
                const string1 = this.Svg?.getElementById('balanceString1');
                if (string0 && string1) {
                    this.#BalanceStrings = [string0, string1];
                }
            }

            if (!this.#BalanceAttaches) {
                this.#BalanceAttaches
                    = [0, 1]
                    .map(i => this.Svg?.getElementById(`balanceAttach${i}`))
                        .filter(i => i);
                if (this.#BalanceAttaches.length < 2) {
                    this.#BalanceAttaches = null;
                }
            }

            if (!this.#BalanceHandles) {
                this.#BalanceHandles
                    = [0, 1]
                        .map(i => this.Svg?.getElementById(`balanceHandle${i}`))
                        .filter(i => i);
                if (this.#BalanceHandles.length < 2) {
                    this.#BalanceHandles = null;
                }
            }

            if (this.#BalanceStrings && this.#BalanceAttaches && this.#BalanceHandles) {
                for (let i = 0; i < 2; ++i) {
                    const rope = this.#BalanceStrings[i];
                    const attach = this.#BalanceAttaches[i];
                    const cx = Number.parseFloat(attach.getAttribute('cx'));
                    const cy = Number.parseFloat(attach.getAttribute('cy'));
                    const handle = this.#BalanceHandles[i];
                    const attachCenter = this.Svg.createSVGMatrix();
                    attachCenter.a = 1;
                    attachCenter.b = 0;
                    attachCenter.c = 0;
                    attachCenter.d = 1;
                    attachCenter.e = cx;
                    attachCenter.f = cy;
                    const matrix0a = attach.getCTM();
                    const matrix1 = handle.getCTM();
                    if (!matrix0a || !matrix1) {
                        continue;
                    }
                    const matrix0 = matrix0a.multiply(attachCenter);
                    const x0 = matrix0?.e;
                    const y0 = matrix0?.f;
                    const x1 = matrix1?.e;
                    const y1 = matrix1?.f;
                    rope.setAttribute('d', `M ${x0},${y0} ${x1},${y1}`);
                }
            }
        }
    }

    _LoadedRoom() {
        super._LoadedRoom();

        if (!this.Client) {
            const svg = this.Svg;

            // Room exit.
            const exit = new Element();
            exit.NameNext = 'exit';
            exit.RoomNext = this;
            exit.ElementIdNext = 'exit';
            this.AddChildNext(exit);

            // Dial puzzle.
            for (let i = 0; i < 3; ++i) {
                const dial = this.#PinWheel(`wheel${i}`);
                this.#Dials.push(dial);
                this.#Wheels.push(dial);
            }
            this.#Dials[1].VisibleNext = false;
            this.#Dials[2].VisibleNext = false;

            // Fake dial.
            const dial1ProxyGroup = svg.getElementById('proxywheel1');
            const dial1Fixtures = [...dial1ProxyGroup.getElementsByTagName('circle')];
            this.#DialProxy1 = this._Body(dial1ProxyGroup, dial1Fixtures, true, null, 0x2, 0x5);
            this.#DialProxy1.VisibleNext = false;
            this.#HeldStatus.set(this.#DialProxy1, false);

            // Door 1
            const door1 = new Element();
            door1.NameNext = 'door1';
            door1.RoomNext = this;
            door1.ElementIdNext = 'door1';
            this.AddChildNext(door1);

            // Exit 1
            const exit1 = new Element();
            exit1.NameNext = 'exit1';
            exit1.RoomNext = this;
            exit1.ElementIdNext = 'exit1';
            this.AddChildNext(exit1);

            // Rivet puzzle.
            for (let i = 0; i < 7; ++i) {
                const rivet = this.#PinWheel(`rivet${i}`);
                this.#RivetTicks.set(rivet, { index: i, tick: -1 });
                this.#Wheels.push(rivet);
            }

            // Safe door.
            const safeDoor = new Element();
            this.#SafeDoor = safeDoor;
            safeDoor.RoomNext = this;
            safeDoor.ElementIdNext = 'safedoor';
            this.AddChildNext(safeDoor);
            // Safe buttons.
            for (let i = 0; i < 10; ++i) {
                this.#Buttons[i] = this.#Button(i);
            }

            // Glasses.
            const glassesGroup = svg.getElementById('glasses');
            const fixtureElements = [svg.getElementById('glassesbody')];
            const glasses = this._Body(glassesGroup, fixtureElements, true, new Glasses(), 0x2, 0x3);
            this.#HeldStatus.set(glasses, false);

            // Torch.
            const torchGroup = svg.getElementById('torch');
            const torchFixtures = [...torchGroup.getElementsByTagName('rect')];
            const torch =this._Body(torchGroup, torchFixtures, true, new Torch(), 0x2, 0x5);
            this.#HeldStatus.set(torch, false);

            // Word sequence bodies.
            for (let i = 0; i < 8; ++i) {
                const id = `ws${i}`;
                const group = svg.getElementById(id);
                const rects = [...group.getElementsByTagName('rect')];
                const circles = [...group.getElementsByTagName('circle')];
                const fixtures = rects.concat(circles);
                const dynamic = i !== 1;
                const body = this._Body(group, fixtures, dynamic, null, 0x2, 0x3);
                this.#HeldStatus.set(body, false);
                this.#Jars[i] = body;

                if (i === 0) {
                    const pin = new Pin();
                    const elementToWorld = this._GetElementTransform(group);
                    const elementTransform = this.DecomposeMatrix(elementToWorld);
                    const rect = rects[0];
                    const width = Number.parseFloat(rect.getAttribute('width'));
                    const height = Number.parseFloat(rect.getAttribute('height'));
                    const x = Number.parseFloat(rect.getAttribute('x'));
                    const y = Number.parseFloat(rect.getAttribute('y'));
                    const xMin = x;
                    const yMin = y + height;
                    const pinPosWorld = new float2(xMin, yMin).Multiply2x3(float2x3.FromDOMMatrix(elementToWorld));
                    const bodyTranslateInverse = new float2x3().MakeTranslation(elementTransform.translate.MultiplyScalar(-1));
                    const bodyRotateInverse = new float2x3().MakeRotation(-elementTransform.rotate);
                    const worldToBody = bodyRotateInverse.Multiply(bodyTranslateInverse);
                    const bodyPos = pinPosWorld.Clone().Multiply2x3(worldToBody);
                    const minRot = 0;
                    const maxRot = 0;
                    const rotSpeed = null;
                    const maxTorque = null;
                    pin.InitialiseNext(this, body, bodyPos, null, pinPosWorld.ToSimple(), minRot, maxRot, rotSpeed, maxTorque);
                    body.AddChildNext(pin);
                    this.#Jar0Pin = pin;
                }
            }

            // Plinth bodies.
            for (let i = 0; i < 8; ++i) {
                const id = `p${i}`;
                const group = svg.getElementById(id);
                const rects = [...group.getElementsByTagName('rect')];
                const fixtures = rects;
                const dynamic = false;
                const body = this._Body(group, fixtures, dynamic, null, 0x1, 0xffff);
                this.#HeldStatus.set(body, false);
                this.#Plinths[i] = body;
            }

            // Static collision.
            const staticGroup = svg.getElementById('staticcollision');
            const staticElements = [...staticGroup.children];
            const staticBody = this._Body(staticGroup, staticElements, false, null, 0x1, 0xffff);
            staticBody.VisibleNext = false;

            // Safe collision.
            const safeCollision = svg.getElementById('safecollision');
            const safeElements = [...safeCollision.children];
            const safeBody = this._Body(safeCollision, safeElements, false, null, 0x4, 0xffff)
            safeBody.VisibleNext = false;

            // Sounds.
            this._AddSound('tick', '/static/snd/tick.mp3');
            this._AddSound('buttondown', '/static/snd/buttondown.mp3');
            this._AddSound('buttonup', '/static/snd/buttonup.mp3');
            this._AddSound('switch', '/static/snd/switch.mp3');
            this._AddSound('switch', '/static/snd/switch.mp3');
            this._AddSound('dooropen', '/static/snd/metaldooropen.mp3');
            this._AddSound('safeopen', '/static/snd/safeopen.mp3');
            this._AddSound('woodhit', '/static/snd/woodhit.mp3');
            this._AddSound('jaropen', '/static/snd/jaropen.mp3');
            this._AddSound('itemattach', '/static/snd/itemattach.mp3');
            this._AddSound('scrapeloop', '/static/snd/scrapeloop.mp3');

            // Balance puzzle.
            {
                const balance = svg.getElementById('balance');
                const fixtures = [...balance.getElementsByTagName('rect')];
                const dynamic = true;
                const balanceBody = this._Body(balance, fixtures, dynamic, null, 4, 4);
                const balanceToWorld = this._GetElementTransform(balance);
                const worldToBalance = balanceToWorld.inverse();

                const balanceCollision = svg.getElementById('balanceCollision');
                const balanceCollisionFixtures = [
                    ...balanceCollision.getElementsByTagName('rect'),
                    ...balanceCollision.getElementsByTagName('path')];
                this._Body(balanceCollision, balanceCollisionFixtures, false, null, 4, 5);

                const wheel2Proxy = svg.getElementById('proxywheel2');
                const wheel2ProxyFixtures = [...wheel2Proxy.getElementsByTagName('circle')];
                this.#DialProxy2 = this._Body(wheel2Proxy, wheel2ProxyFixtures, true, null, 12, 4);
                this.#HeldStatus.set(this.#DialProxy2, false);

                const balancePlunger = svg.getElementById('balancePlunger');
                const balancePlungerFixtures = [...balancePlunger.getElementsByTagName('path')];
                const plunger = this._Body(balancePlunger, balancePlungerFixtures, true, null, 4, 9);
                {
                    const plungerStart = this._GetElementTransform(balancePlunger);
                    const plungerEnd = this._GetElementTransform(svg.getElementById('balancePlungerEnd'));

                    const prismaticJointDef = {
                        bodyA: null,
                        localAnchorA: new planck.Vec2().set(plungerStart.e, plungerStart.f).mul(1 / Room.pixelsPerMeter),
                        bodyB: plunger,
                        localAnchorB: new planck.Vec2(0, 0),
                        localAxisA: new planck.Vec2(0, -1),
                        enableLimit: true,
                        lowerTranslation: 0,
                        upperTranslation: (plungerStart.f - plungerEnd.f) / Room.pixelsPerMeter,
                    };
                    this.#PrismaticJointDefs.push(prismaticJointDef);
                }

                // Balance exit
                const balanceExit = svg.getElementById('balanceExit');
                const beFixtures = [...balanceExit.getElementsByTagName('rect')];
                this._balanceExitBody = this._Body(balanceExit, beFixtures, false, null, 4, 4, { isSensor: true });
                this._balanceExitBody.VisibleNext = false;

                const handleBodies = [];
                for (let i = 0; i < 2; ++i) {
                    const id = `balanceHandle${i}`;
                    const handle = svg.getElementById(id);
                    const fixtures = [...handle.getElementsByTagName('circle')];
                    const dynamic = true;
                    const handleBody = this._Body(handle, fixtures, dynamic, null, 0, 0);
                    handleBodies.push(handleBody);
                    const handleToWorld = this._GetElementTransform(handle);
                    this.#HeldStatus.set(handleBody, false);

                    const attachId = `balanceAttach${i}`;
                    const attach = svg.getElementById(attachId);
                    const attachToWorld = this._GetElementTransform(attach);
                    const attachX = Number.parseFloat(attach.getAttribute('cx'));
                    const attachY = Number.parseFloat(attach.getAttribute('cy'));
                    const localPoint = new DOMPoint(attachX, attachY);
                    const attachToBalance = worldToBalance.multiply(attachToWorld);
                    const balanceAttachPoint = localPoint.matrixTransform(attachToBalance);

                    const endId = `balanceEnd${i}`;
                    const end = svg.getElementById(endId).children[0];
                    const endToWorld = this._GetElementTransform(end);
                    const endX = Number.parseFloat(end.getAttribute('cx'));
                    const endY = Number.parseFloat(end.getAttribute('cy'));
                    let endPoint = new DOMPoint(endX, endY);
                    endPoint = endPoint.matrixTransform(endToWorld);

                    const worldAttachPointA = balanceAttachPoint.matrixTransform(balanceToWorld);
                    const worldAttachPointB = new DOMPoint(0, 0).matrixTransform(handleToWorld);
                    const dx = worldAttachPointB.x - worldAttachPointA.x;
                    const dy = worldAttachPointB.y - worldAttachPointA.y;
                    const maxLength = Math.sqrt(dx * dx + dy * dy) / Room.pixelsPerMeter;

                    const endDx = endPoint.x - worldAttachPointB.x;
                    const endDy = endPoint.y - worldAttachPointB.y;
                    const upperTranslation = Math.sqrt(endDx * endDx + endDy * endDy) / Room.pixelsPerMeter;

                    const bodyA = balanceBody;
                    const localAnchorA = new planck.Vec2(balanceAttachPoint.x, balanceAttachPoint.y).mul(1 / Room.pixelsPerMeter);
                    const bodyB = handleBody;
                    const localAnchorB = new planck.Vec2(0, 0);

                    const ropeJointDef = {
                        bodyA,
                        localAnchorA,
                        bodyB,
                        localAnchorB,
                        maxLength,
                    };
                    this.#RopeJointDefs.push(ropeJointDef);

                    const prismaticJointDef = {
                        bodyA: null,
                        localAnchorA: new planck.Vec2().set(worldAttachPointB).mul(1 / Room.pixelsPerMeter),
                        bodyB,
                        localAnchorB,
                        localAxisA: new planck.Vec2(0, -1),
                        enableLimit: true,
                        lowerTranslation: 0,
                        upperTranslation,
                    };
                    this.#PrismaticJointDefs.push(prismaticJointDef);
                }
            }
        }
    }

    #Button(index) {
        const buttonName = `button${index}`;
        const button = new Button();
        button.RoomNext = this;
        button.ElementIdNext = buttonName;
        this.AddChildNext(button);

        const segmentName = `sevseg${index}`;
        const sevenSegment = new SevenSegment();
        sevenSegment.RoomNext = this;
        sevenSegment.ElementIdNext = segmentName;
        sevenSegment.CharacterNext = String.fromCharCode('0'.charCodeAt(0) + index);
        sevenSegment.ColorSeedNext = Math.abs((Math.random() * 0xFFFFFFFF) | 0);
        this.AddChildNext(sevenSegment);

        return {
            button,
            sevenSegment
        };
    }

    #PinWheel(bodyName) {
        const element = this.Svg.getElementById(bodyName);
        const elementToWorld = this._GetElementTransform(element);
        const elementTransform = this.DecomposeMatrix(elementToWorld);
        const worldToElement = elementToWorld.inverse();

        const ce = element.getElementsByTagName('circle')[0];
        // Get the collision matrix PBC
        const collisionToWorld = this._GetElementTransform(ce);
        const radius = Number.parseFloat(ce.getAttribute('r')) * 2;
        const x = Number.parseFloat(ce.getAttribute('cx'));
        const y = Number.parseFloat(ce.getAttribute('cy'));
        const collisionMatrix = new DOMMatrix([radius, 0, 0, radius, x, y]);
        collisionToWorld.multiplySelf(collisionMatrix);
        // Decompose collision matrix into size position and rotation.
        const collisionTransform = this.DecomposeMatrix(collisionToWorld);
        const circleRadius = collisionTransform.scale.x;
        const circlePosition = collisionTransform.translate;

        const fixtures = [
            {
                type: 'circle',
                radius: circleRadius,
                position: circlePosition.ToSimple(),
                typeMask: 0x0,
                filterMask: 0x0,
            }
        ];

        const body = new Body();
        body.NameNext = bodyName;
        body.RoomNext = this;
        body.ElementIdNext = bodyName;
        body.InitialiseNext(elementTransform.translate, elementTransform.rotate, elementTransform.scale, fixtures, true);

        const pin = new Pin();
        const bodyTranslateInverse = new float2x3().MakeTranslation(elementTransform.translate.MultiplyScalar(-1));
        const bodyRotateInverse = new float2x3().MakeRotation(-elementTransform.rotate);
        const worldToBody = bodyRotateInverse.Multiply(bodyTranslateInverse);
        const bodyPos = circlePosition.Clone().Multiply2x3(worldToBody);
        pin.InitialiseNext(this, body, bodyPos, null, circlePosition.ToSimple(), null, null, null, null);
        body.AddChildNext(pin);

        this.AddChildNext(body);

        return body;
    }

    #ClientExitOnClick(event) {
        const player = this.Client.Player;
        player.ClickedNext = this.#Exit;
    }

    #ClientExit1OnClick(event) {
        const player = this.Client.Player;
        const door1 = this.FindDescendent('door1');
        if (door1 && !door1.Visible) {
            player.ClickedNext = this.#Exit1;
        }
    }

    #ServerExitOnClick(target, player) {
        this._OnExit(target, player, this);
    }
}

SyncObject.RegisterType(Room01, 'Room01');
