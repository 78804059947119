import { Body } from "./Body.js";
import { SyncObject } from "../Engine/SyncObject.js";

export class Torch extends Body {
    #Zoom = 1;

    constructor() {
        super();
    }

    get Zoom() {
        return this.#Zoom;
    }

    set ZoomNext(value) {
        this._SendSetProperty('Zoom', value);
    }

    Update(time, dt) {
        super.Update(time, dt);

        if (this.Client) {
            const holders = [...this.HeldBy];
            holders.sort((a, b) => {
                const ai = a.IsClientPlayer() ? -1 : a.PlayerId;
                const bi = b.IsClientPlayer() ? -1 : b.PlayerId;
                return ai - bi;
            });
            const firstHolder = holders.length && holders[0];
            const torch = this.Svg;
            const torchlight = document.getElementById('torchlight');
            if (torch && torchlight) {
                torchlight.style.pointerEvents = 'none'; // Ignore clicks.
                if (firstHolder) {
                    torch.style.display = 'none';
                    torchlight.style.display = 'inherit';
                    const transform = torchlight.transform.baseVal;
                    const viewportElement = torch.viewportElement;
                    if (transform.length !== 1) {
                        let item = viewportElement.createSVGTransform();
                        item.setTranslate(0, 0);
                        transform.appendItem(item);
                    }
                    const matrix = viewportElement.createSVGMatrix();
                    const position = firstHolder.Position;
                    let zoom = this.#Zoom;
                    if (firstHolder.IsClientPlayer) {
                        zoom = this.ZoomNext = this.Client.Zoom;
                    }
                    const scale = 1 / zoom;
                    matrix.a = scale;
                    matrix.d = scale;
                    matrix.e = position.x;
                    matrix.f = position.y;
                    transform.getItem(0).setMatrix(matrix);
                }
                else {
                    torch.style.display = 'inherit';
                    torchlight.style.display = 'none';
                }
            }
        }
    }

    _ReceiveSetProperty(name, value) {
        switch (name) {
            case 'Zoom':
                this.#Zoom = value;
                break;
            default:
                super._ReceiveSetProperty(name, value);
                break;
        }
    }
}

SyncObject.RegisterType(Torch, 'Torch');