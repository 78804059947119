import { SyncObject } from "../Engine/SyncObject.js";
import { Element } from "./Element.js";
import { Room } from "./Room.js";

export class Room02 extends Room {
    #Exit = null;

    Update(time, dt) {
        super.Update(time, dt);

        if (!this.Client) {
            // Server update.
            const open1 = this.Handle1?.Rotation < -0.1 * 2 * Math.PI;
            const open2 = this.Handle2?.Rotation < -0.1 * 2 * Math.PI;
            if (open1 && open2 && this.Door) {
                this.Door.VisibleNext = false;
            }
        }

        // Initialise the exit element.
        if (!this.#Exit) {
            this.#Exit = this.FindDescendent('exit');
            if (this.#Exit) {
                if (this.Client) {
                    this.#Exit.OnClick.add(this.#ClientExitOnClick.bind(this));
                }
                else {
                    this.#Exit.OnClick.add(this.#ServerExitOnClick.bind(this));
                }
            }
        }
    }

    _LoadedRoom() {
        super._LoadedRoom();

        if (!this.Client) {
            // Room exit.
            const exit = new Element();
            exit.NameNext = 'exit';
            exit.RoomNext = this;
            exit.ElementIdNext = 'exit';
            this.AddChildNext(exit);
        }
    }

    #ClientExitOnClick(event) {
        const player = this.Client.Player;
        player.ClickedNext = this.#Exit;
    }

    #ServerExitOnClick(target, player) {
        this._OnExit(target, player, this);
    }
}

SyncObject.RegisterType(Room02, 'Room02');
