import { Simpesc } from "./Simpesc/Simpesc.js";

async function RegisterServiceWorkerAsync() {
    if ("serviceWorker" in navigator) {
        try {
            await navigator.serviceWorker.register("/serviceworker.js");
        }
        catch (e) {
            console.error('Error registering service worker', e);
        }
    }
}

RegisterServiceWorkerAsync();

let application = null;

function UpdateStatus() {
    if (!application?.IsHost) {
        document.getElementById('status').innerHTML = '<div class="column"><div>Joining Game...</div><div id="joinCancelButton" class="button">Cancel</div></div>';
        document.getElementById('joinCancelButton').addEventListener('click', NewOnClick);
    }
}

async function InviteOnClickAsync() {
    const dialogLayer = document.getElementById('dialogLayer');
    const dialogContainer = document.getElementById('dialogContainer');
    dialogContainer.innerHTML = `
        <div class='label'>Invite code</div>
        <input id='inviteInput'></input>
        <div>&nbsp;</div>
        <div id="doneButton" class="button">Done</div>`;
    dialogLayer.style.display = 'flex';
    const doneButton = document.getElementById('doneButton');
    doneButton.addEventListener(
        'click',
        event => {
            dialogLayer.style.display = 'none';
        });
    const inviteInput = document.getElementById('inviteInput');
    inviteInput.value = 'please wait...';
    inviteInput.readOnly = true;

    const gameId = application.Id;
    const url = `/api/Values/?value=${gameId}`;
    const response = await fetch(
        url,
        {
            method: 'PUT',
        });
    const json = await response.json();
    inviteInput.value = json.id;
}

function JoinOnClick() {
    const dialogLayer = document.getElementById('dialogLayer');
    const dialogContainer = document.getElementById('dialogContainer');
    dialogContainer.innerHTML = `
        <input id='inviteInput' placeholder='Enter invite code'></input>
        <div>&nbsp;</div>
        <div id="doneButton" class="button">Join Game</div>`;
    dialogLayer.style.display = 'flex';

    const inviteInput = document.getElementById('inviteInput');
    const doneButton = document.getElementById('doneButton');

    const doneOnClick = async () => {
        const valueId = inviteInput.value;
        const url = `/api/Values/?id=${valueId}`;
        const response = await fetch(url);
        const json = await response.json();
        const gameId = json.value;
        application.Stop();
        application = new Simpesc();
        application.Clear();
        application.Start(gameId);
        UpdateStatus();
        window.simpesc = application;
        dialogLayer.style.display = 'none';
    }

    doneButton.addEventListener('click', doneOnClick);

    inviteInput.addEventListener('keyup', async event => {
        const enterKeycode = 13;
        if (event.keyCode === enterKeycode) {
            await doneOnClick();
        }
    });
    inviteInput.focus();
}

function NewOnClick() {
    const dialogLayer = document.getElementById('dialogLayer');
    const dialogContainer = document.getElementById('dialogContainer');
    dialogContainer.innerHTML = `
        <div>Are you sure you want to leave the current game?</div>
        ${application?.IsHost ? `<div>&nbsp;</div>
        <div>Starting a new game will lose all progress and</div>
        <div>remove all players from the current game.</div>` : ''}
        <div class='row'>
            <div class="grow"></div>
            <div id="yesButton" class="button content">Yes</div>
            <div id="noButton" class="button content">No</div>
            <div class="grow"></div>
        </div>`;
    dialogLayer.style.display = 'flex';
    const yesButton = document.getElementById('yesButton');
    yesButton.addEventListener(
        'click',
        async event => {
            application.Stop();
            application = new Simpesc();
            application.Clear();
            application.Start();
            UpdateStatus();
            window.simpesc = application;
            dialogLayer.style.display = 'none';
        });
    const noButton = document.getElementById('noButton');
    noButton.addEventListener(
        'click',
        async event => {
            dialogLayer.style.display = 'none';
        });
}

function DialogLayerOnClick(event) {
    const dialogLayer = document.getElementById('dialogLayer');
    dialogLayer.style.display = 'none';

    window.history.pushState('', '', '/');
}

function DialogContainerOnClick(event) {
    event.stopPropagation();
}

async function DialogFromPage(name) {
    const url = '/static/' + name + ".html";
    const dialogLayer = document.getElementById('dialogLayer');
    const dialogContainer = document.getElementById('dialogContainer');
    dialogContainer.innerHTML = `
        <div>One moment please...</div>`;
    dialogLayer.style.display = 'flex';

    const response = await fetch(url);
    const html = await response.text();
    dialogContainer.innerHTML = html;

    window.history.pushState('', '', '/' + name);
}

function AboutLinkOnClick(event) {
    event.preventDefault();
    DialogFromPage('about');
    return false;
}

async function ContactLinkOnClick(event) {
    event.preventDefault();
    await DialogFromPage('contact');
    const button = document.getElementById('contactSendButton');
    button.addEventListener('click', async event => {
        event.preventDefault();
        const email = document.getElementById('Email').value;
        const message = document.getElementById('Message').value;
        await fetch(
            '/api/contact',
            {
                method: 'Post',
                body: JSON.stringify({
                    email,
                    message,
                }),
                headers: {
                    'Content-Type': 'application/json',
                },
            });
    });
    return false;
}

function CookiesLinkOnClick(event) {
    event.preventDefault();
    DialogFromPage('cookies');
    return false;
}

function PrivacyLinkOnClick(event) {
    event.preventDefault();
    DialogFromPage('privacy');
    return false;
}

function PickOverlayFromUrl() {
    const url = new URL(document.location);
    const fakeEvent = {
        preventDefault: () => { },
    };
    switch (url.pathname.toLowerCase()) {
        case '/about':
            AboutLinkOnClick(fakeEvent);
            break;
        case '/contact':
            ContactLinkOnClick(fakeEvent);
            break;
        case '/cookies':
            CookiesLinkOnClick(fakeEvent);
            break;
        case '/privacy':
            PrivacyLinkOnClick(fakeEvent);
            break;
        default:
            {
                const dialogLayer = document.getElementById('dialogLayer');
                dialogLayer.style.display = 'none';
            }
            break;
    }
}

function BeforeUnload(event) {
    event.preventDefault();
    event.returnValue = `Are you sure you want to leave the current game?${application?.IsHost ? `
Starting a new game will lose all progress and remove all players from the current game.</div>` : ''}`;
    return event.returnValue;
}

var everythingLoaded = setInterval(
    () => {
        if (/loaded|complete/.test(document.readyState) && window.planck !== undefined) {
            clearInterval(everythingLoaded);

            const inviteButton = document.getElementById('inviteButton');
            const joinButton = document.getElementById('joinButton');
            const newButton = document.getElementById('newButton');
            inviteButton.addEventListener('click', InviteOnClickAsync);
            joinButton.addEventListener('click', JoinOnClick);
            newButton.addEventListener('click', NewOnClick);

            const dialogLayer = document.getElementById('dialogLayer');
            dialogLayer.addEventListener('click', DialogLayerOnClick);
            const dialogContainer = document.getElementById('dialogContainer');
            dialogContainer.addEventListener('click', DialogContainerOnClick);

            const aboutLink = document.getElementById('aboutLink');
            aboutLink.addEventListener('click', AboutLinkOnClick);

            const contactLink = document.getElementById('contactLink');
            contactLink.addEventListener('click', ContactLinkOnClick);

            const cookiesLink = document.getElementById('cookiesLink');
            cookiesLink.addEventListener('click', CookiesLinkOnClick);

            const privacyLink = document.getElementById('privacyLink');
            privacyLink.addEventListener('click', PrivacyLinkOnClick);

            const footer = document.getElementById('footer');
            footer.style.display = 'inherit';

            const menuLayer = document.getElementById('menuLayer');
            menuLayer.style.display = 'flex';

            window.addEventListener('beforeunload', BeforeUnload);

            application = new Simpesc();
            application.Start();
            UpdateStatus();
            window.simpesc = application;

            PickOverlayFromUrl();

            window.addEventListener('popstate', event => {
                PickOverlayFromUrl();
            });

            const cookiesAcceptKey = 'cookiesAccept';
            if (!localStorage.getItem(cookiesAcceptKey)) {
                const cookiesPromptLayer = document.getElementById('cookiesPromptLayer');
                cookiesPromptLayer.style.display = 'inherit';
                const cookiesButton = document.getElementById('cookiesButton');
                cookiesButton.addEventListener('click', event => {
                    localStorage.setItem(cookiesAcceptKey, 'true');
                    cookiesPromptLayer.style.display = 'none';
                });
            }

        }
    },
    10);
