import { Child } from "./Child.js";
import { SyncObject } from "../Engine/SyncObject.js";

export class Sound extends Child {
    #Source = null;
    #AudioBuffer = null;
    static #Context = null;
    static #ContextListener = null;
    static #OnInitialise = [];

    constructor() {
        super();
        Sound.#InitialiseAsync();
    }

    get Source() {
        return this.#Source;
    }

    set SourceNext(value) {
        this._SendSetProperty('Source', value);
    }

    Play(volume = 1) {
        const context = Sound.#Context;
        if (!context) {
            return;
        }
        const player = new SoundPlayer(context, this.#AudioBuffer);
        player.Volume = volume;
        return player;
    }

    _ReceiveSetProperty(name, value) {
        switch (name) {
            case 'Source':
                this.#Source = value;
                this.#LoadSourceAsync();
                break;
            default:
                super._ReceiveSetProperty(name, value);
                break;
        }
    }

    static #InitialiseAsync() {
        if (!this.#Context) {
            if (!this.#ContextListener) {
                this.#ContextListener = () => {
                    const AudioContext = window.AudioContext || window.webkitAudioContext;
                    this.#Context = new AudioContext();
                    const audioBufferSourceNode = this.#Context.createBufferSource();
                    const sampleRate = this.#Context.sampleRate;
                    const audioBuffer = this.#Context.createBuffer(1, sampleRate, sampleRate);
                    const channelData = audioBuffer.getChannelData(0);
                    for (let i = 0; i < sampleRate; i += 1) {
                        channelData[i] = 0;
                    }
                    audioBufferSourceNode.buffer = audioBuffer;
                    audioBufferSourceNode.connect(this.#Context.destination);
                    audioBufferSourceNode.start(this.#Context.currentTime);
                    document.body.removeEventListener('pointerdown', Sound.#ContextListener);
                    for (const resolve of Sound.#OnInitialise) {
                        try {
                            resolve();
                        }
                        catch (e) {
                            console.error(e);
                        }
                    }
                    Sound.#OnInitialise = null;
                };
                document.body.addEventListener('pointerdown', Sound.#ContextListener);
            }
            return new Promise((resolve) => {
                Sound.#OnInitialise.push(resolve);
            });
        }
        else {
            return Promise.resolve();
        }
    }

    async #LoadSourceAsync() {
        const source = this.#Source;
        const response = await fetch(source);
        const bytes = await response.arrayBuffer();
        await Sound.#InitialiseAsync();
        const onSuccess = [];
        const onError = [];
        const successHandler = (audioBuffer) => {
            if (source === this.#Source) {
                this.#AudioBuffer = audioBuffer;
            }
            for (const handler of onSuccess) {
                try {
                    handler();
                }
                catch (e) {
                    console.error(e);
                }
            }
        };
        const errorHandler = () => {
            console.error('Error decoding audio data');
            for (const handler of onError) {
                try {
                    handler();
                }
                catch (e) {
                    console.error(e);
                }
            }
        };
        Sound.#Context.decodeAudioData(bytes, successHandler, errorHandler);
        const decodePromise = new Promise((resolve, reject) => {
            onSuccess.push(resolve);
            onError.push(reject);
        });
        await decodePromise;
    }
}

class SoundPlayer {
    #Context = null;
    #Source = null;
    #GainNode = null;

    constructor(context, buffer) {
        var source = context.createBufferSource();
        source.buffer = buffer;
        const gainNode = context.createGain();
        source.connect(gainNode);
        gainNode.connect(context.destination);
        source.start(0);

        this.#Context = context;
        this.#Source = source;
        this.#GainNode = gainNode;
    }

    get Volume() {
        return this.#GainNode.gain.value;
    }

    set Volume(value) {
        const { gain } = this.#GainNode;
        gain.cancelScheduledValues(0);
        gain.value = value;
    }

    get Loop() {
        return this.#Source.loop;
    }

    set Loop(value) {
        this.#Source.loop = !!value;
    }

    AnimateVolume(value, dt) {
        const { gain } = this.#GainNode;
        const { currentTime } = this.#Context;
        const startValue = gain.value;
        gain.cancelScheduledValues(0);
        gain.setValueAtTime(startValue, currentTime);
        gain.exponentialRampToValueAtTime(Math.max(value, 1e-10), currentTime + dt);
    }

    Stop(dt = 0) {
        const { currentTime } = this.#Context;
        this.#Source.stop(currentTime + dt);
    }
}

SyncObject.RegisterType(Sound, 'Sound');
