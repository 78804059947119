import { SyncObject } from "../Engine/SyncObject.js";

export class Game extends SyncObject {
    #Players = new Set();
    #PlayerAvatars = new Map();

    constructor() {
        super();
    }

    get Players() {
        return new Set(this.#Players);
    }

    AddPlayerNext(value) {
        this._SendMessage({
            type: "addplayer",
            value
        });
    }

    RemovePlayerNext(value) {
        this._SendMessage({
            type: "removeplayer",
            value
        });
    }

    GetAvatarUnderCursor(event) {
        const x = event.clientX;
        const y = event.clientY;
        for (const [player, avatar] of this.#PlayerAvatars) {
            const rect = avatar.getBoundingClientRect();
            if (rect.left < x && x < rect.right && rect.top < y && y < rect.bottom) {
                return player;
            }
        }
        return null;
    }

    Update(time, dt) {
        if (this.Client) {
            let updateAvatars = false;
            for (const player of this.#Players) {
                if (!this.#PlayerAvatars.has(player)) {
                    const avatar = document.createElementNS('http://www.w3.org/2000/svg', 'g');
                    const img = document.createElementNS('http://www.w3.org/2000/svg', 'image');
                    const avatarOverlay = document.createElementNS('http://www.w3.org/2000/svg', 'g');
                    avatarOverlay.id = `avatarover${player.PlayerId}`;
                    img.setAttribute('href', '/static/img/avatar.svg');
                    avatar.appendChild(img);
                    avatar.appendChild(avatarOverlay);
                    this.#PlayerAvatars.set(player, avatar);
                    document.getElementById('avatars').appendChild(avatar);
                    updateAvatars = true;
                }
            }

            if (document.getElementById('avatars').childElementCount !== this.#PlayerAvatars.size) {
                updateAvatars = true;
            }

            for (const avatarPlayer of this.#PlayerAvatars.keys()) {
                if (!this.#Players.has(avatarPlayer)) {
                    const avatar = this.#PlayerAvatars.get(avatarPlayer);
                    document.getElementById('avatars').removeChild(avatar);
                    this.#PlayerAvatars.delete(avatarPlayer);
                    updateAvatars = true;
                }
            }

            if (updateAvatars) {
                const children = document.getElementById('avatars').childNodes;
                for (const child of children) {
                    let found = false;
                    for (const avatar of this.#PlayerAvatars.values()) {
                        if (child === avatar) {
                            found = true;
                        }
                    }
                    if (!found) {
                        document.getElementById('avatars').removeChild(child);
                    }
                }

                const players = [...this.#PlayerAvatars.keys()];
                players.sort((a, b) => {
                    const ai = a.IsClientPlayer ? -1 : a.PlayerId;
                    const bi = b.IsClientPlayer ? -1 : b.PlayerId;
                    return ai - bi;
                });
                const height = 256;
                const spacing = 8;
                const clientPlayerScale = 0.4;
                const otherPlayerScale = clientPlayerScale * 0.75;
                const x = spacing;
                let y = spacing;
                let scale = clientPlayerScale;
                for (const player of players) {
                    const avatar = this.#PlayerAvatars.get(player);
                    const transform = avatar.transform.baseVal;
                    const matrix = avatar.viewportElement.createSVGMatrix();
                    if (transform.length === 0) {
                        const item = avatar.viewportElement.createSVGTransform();
                        item.setMatrix(matrix);
                        transform.appendItem(item);
                    }
                    matrix.a = scale;
                    matrix.d = scale;
                    matrix.e = x;
                    matrix.f = y;
                    transform.getItem(0).setMatrix(matrix);
                    y += (height * scale) + spacing;
                    scale = otherPlayerScale;
                    const hue = player.Hue;
                    const hsl = `hsl(${hue * 360},50%,50%)`;
                    fetch('/static/img/avatar.svg')
                        .then(response => response.text())
                        .then(text => {
                            text = text.replaceAll('#ffffff', hsl);
                            const dataUrl = 'data:image/svg+xml;base64,' + btoa(text);
                            avatar.children[0].setAttribute('href', dataUrl);
                        });
                }
            }
        }
    }

    _ReceiveMessage(message) {
        switch (message.type) {
            case "addplayer":
                {
                    const value = message.value;
                    if (!this.#Players.has(value)) {
                        this.#Players.add(value);
                    }
                }
                break;
            case "removeplayer":
                {
                    const value = message.value;
                    if (this.#Players.has(value)) {
                        this.#Players.delete(value);
                    }
                }
                break;
            default:
                super._ReceiveMessage(message);
                break;
        }
    }

    _ClassifyMessages(messageMap) {
        super._ClassifyMessages(messageMap);

        const lastPlayer = new WeakMap();

        for (const message of messageMap.keys()) {
            switch (message.type) {
                case 'addplayer':
                case 'removeplayer':
                    const child = message.value;
                    if (lastPlayer.has(child)) {
                        messageMap.set(message, false);
                    }
                    lastPlayer.set(child, message);
                    messageMap.set(message, true);
                    break;
                default:
                    break;
            }
        }
    }
}

SyncObject.RegisterType(Game, 'Game');
