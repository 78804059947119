import { Connection } from "./Connection.js";

const StageNone = 0;
const StageInitialize = 1;
const StageOffer = 2;
const StageAnswer = 3;
const StageIce = 4;
const StageConnected = 5;

export class RemoteConnection extends Connection {
    #SignalConnection = null;
    #OrderedConnection = null;
    #PeerConnection = null;
    #DataChannel = null;
    #IsClient = false;
    #Stage = StageNone;
    #OnSignalBind = null;
    #DataChannelOnMessageBind = null;
    #OnIceCandidateBind = null;
    #OnConnectionStateChangeBind = null;
    #OnResetBind = null;
    #InitializeTime = -1;
    #InitializationTimes = new WeakMap();
    #IceCandidates = [];

    constructor(signalConnection, isClient) {
        super();
        this.#OnSignalBind = this.#OnSignal.bind(this);
        this.#DataChannelOnMessageBind = this.#DataChannelOnMessage.bind(this);
        this.#OnIceCandidateBind = this.#OnIceCandidate.bind(this);
        this.#OnConnectionStateChangeBind = this.#OnConnectionStateChange.bind(this);
        this.#OnResetBind = this.#OnReset.bind(this);

        this.#SignalConnection = signalConnection;
        this.#IsClient = isClient;

        this.#OrderedConnection = new OrderedConnection(signalConnection);
        this.#OrderedConnection.OnMessage.add(this.#OnSignalBind);
        this.#OrderedConnection.OnReset.add(this.#OnResetBind);
        this.#Stage = StageInitialize;
    }

    get Id() {
        return this.#SignalConnection.Id;
    }

    Send(data) {
        try {
            if (this.#DataChannel?.readyState === 'open') {
                this.#DataChannel.send(data);
            }
        }
        catch (e) {
            console.error('Error sending data to remote', e);
        }
    }

    async Update(time) {
        this.#OrderedConnection.Update();

        switch (this.#Stage) {
            case StageInitialize:
                this.#InitializeAsync();
                break;
            case StageOffer:
                break;
            case StageAnswer:
                break;
            case StageIce:
                while (this.#IceCandidates.length > 0) {
                    const iceCandidate = this.#IceCandidates[0];
                    this.#IceCandidates.splice(0, 1);
                    try {
                        await this.#PeerConnection.addIceCandidate(iceCandidate);
                    } catch (e) {
                        console.error('Error adding received ice candidate', iceCandidate, e);
                    }
                }
                break;
            case StageConnected:
                break;
            default:
                this.#Stage = StageInitialize;
                break;
        }
    }

    async #OnSignal(message) {
        message = JSON.parse(message);
        if (message.answer) {
            const remoteDesc = new RTCSessionDescription(message.answer);
            const peerConnection = this.#PeerConnection;
            await peerConnection.setRemoteDescription(remoteDesc);
            if (peerConnection !== this.#PeerConnection) {
                return;
            }
            this.#Stage = StageIce;
        }
        if (message.offer) {
            const remoteDesc = new RTCSessionDescription(message.offer);
            const peerConnection = this.#PeerConnection;
            peerConnection.setRemoteDescription(remoteDesc);
            const answer = await peerConnection.createAnswer();
            if (peerConnection !== this.#PeerConnection) {
                return;
            }
            await peerConnection.setLocalDescription(answer);
            if (peerConnection !== this.#PeerConnection) {
                return;
            }
            this.#SendSignal({ answer });
            this.#Stage = StageIce;
        }
        if (message.iceCandidate) {
            this.#IceCandidates.push(message.iceCandidate);
        }
    }

    #SendSignal(message) {
        this.#OrderedConnection.Send(JSON.stringify(message));
    }

    async #InitializeAsync() {
        this.#InitializeTime = Math.round(Date.now());


        if (this.#DataChannel) {
            try {
                this.#DataChannel.removeEventListener('message', this.#DataChannelOnMessageBind);
            }
            catch (e) {
                console.error(e);
            }

            try {
                this.#DataChannel.close();
            }
            catch (e) {
                console.error(e);
            }

            try {
                this.#DataChannel = null;
            }
            catch (e) {
                console.error(e);
            }
        }
        if (this.#PeerConnection) {
            try {
                this.#PeerConnection.removeEventListener('icecandidate', this.#OnIceCandidateBind);
            }
            catch (e) {
                console.error(e);
            }

            try {
                this.#PeerConnection.removeEventListener('connectionstatechange', this.#OnConnectionStateChangeBind);
            }
            catch (e) {
                console.error(e);
            }

            try {
                this.#PeerConnection.close();
            }
            catch (e) {
                console.error(e);
            }

            try {
                this.#PeerConnection = null;
            }
            catch (e) {
                console.error(e);
            }
        }

        const configuration = {
            iceServers: [
                {
                    urls: 'stun:turnuk.escaperclub.com:3478',
                },
                {
                    urls: 'turn:turnuk.escaperclub.com:3478',
                    username: 'abc',
                    credential: '123',
                },
                {
                    urls: 'stun:stun.services.mozilla.com:3478',
                },
            ],
        };
        const peerConnection = this.#PeerConnection = new RTCPeerConnection(configuration);
        const dataChannel = this.#DataChannel = peerConnection.createDataChannel(
            'data',
            {
                ordered: false,
                maxRetransmits: 0,
                negotiated: true,
                id: 0,
            });
        dataChannel.binaryType = 'arraybuffer';
        dataChannel.addEventListener('message', this.#DataChannelOnMessageBind);

        this.#InitializationTimes.set(peerConnection, this.#InitializeTime);
        this.#InitializationTimes.set(dataChannel, this.#InitializeTime);
        this.#IceCandidates = [];

        if (this.#IsClient) {
            this.#Stage = StageOffer;
            const offer = await peerConnection.createOffer();
            await peerConnection.setLocalDescription(offer);
            if (this.#InitializationTimes.get(peerConnection) !== this.#InitializeTime) {
                return;
            }
            this.#SendSignal({ offer });
        }
        else {
            this.#Stage = StageAnswer;
        }

        peerConnection.addEventListener('icecandidate', this.#OnIceCandidateBind);
        peerConnection.addEventListener('connectionstatechange', this.#OnConnectionStateChangeBind);
    }

    #DataChannelOnMessage(event) {
        this._OnMessage(event.data);
    }

    #OnIceCandidate(event) {
        const iceCandidate = event.candidate;
        if (iceCandidate) {
            this.#SendSignal({ iceCandidate });
        }
    }

    #OnConnectionStateChange(event) {
        console.log('Peer', this.#PeerConnection.connectionState);
        if (this.#IsClient) {
            switch (this.#PeerConnection.connectionState) {
                case 'disconnected':
                case 'failed':
                case 'closed':
                    this.#OrderedConnection.OnMessage.delete(this.#OnSignalBind);
                    this.#OrderedConnection.OnReset.delete(this.#OnResetBind);
                    this.#OrderedConnection = new OrderedConnection(this.#SignalConnection);
                    this.#OrderedConnection.OnMessage.add(this.#OnSignalBind);
                    this.#OrderedConnection.OnReset.add(this.#OnResetBind);
                    this.#OnReset();
                    break;
                default:
                    break;
            }
        }
    }

    #OnReset() {
        console.log('reset');
        this.#InitializeAsync();
    }
}

class OrderedConnection extends Connection {
    #Connection = null;
    #Messages = [];
    #MessageIdSent = 0;
    #MessageIdReceived = 0;
    #CreationTime = Math.floor(Date.now());
    #RemoteCreationTime = null;
    #OnReset = new Set();
    #ConnectionOnMessageBind = null;

    constructor(connection) {
        super();
        this.#Connection = connection;
        this.#ConnectionOnMessageBind = this.#ConnectionOnMessage.bind(this);

        this.#Connection.OnMessage.add(this.#ConnectionOnMessageBind);
    }

    get Id() {
        return this.#Connection.Id;
    }

    get OnReset() {
        return this.#OnReset;
    }

    Send(data) {
        const message = {
            ct: this.#CreationTime,
            mi: this.#MessageIdSent++,
            m: data
        };
        const messageJson = JSON.stringify(message);
        this.#Messages.push({
            message,
            messageJson,
            sendTime: 0,
        });
        this.Update();
    }

    Update() {
        const now = Date.now();
        const firstMessage = this.#Messages.length > 0 && this.#Messages[0];
        if (firstMessage) {
            if (now > firstMessage.sendTime + 100) {
                // Resend.
                this.#Connection.Send(firstMessage.messageJson);
                firstMessage.sendTime = now;
            }
        }
    }

    #ConnectionOnMessage(message) {
        message = JSON.parse(message);

        if (message.ct !== this.#RemoteCreationTime) {
            if (this.#RemoteCreationTime === null) {
                this.#RemoteCreationTime = message.ct;
            }
            else {
                if (message.ct < this.#RemoteCreationTime) {
                    return;
                }
                else {
                    this.#Messages = [];
                    this.#MessageIdSent = 0;
                    this.#MessageIdReceived = 0;
                    this.#RemoteCreationTime = message.ct;
                    for (const handler of this.OnReset) {
                        try {
                            handler.call(this);
                        }
                        catch (e) {
                            console.error(e);
                        }
                    }
                }
            }
        }
        
        if (message.ack !== undefined) {
            const firstMessage = this.#Messages.length > 0 && this.#Messages[0];
            if (firstMessage) {
                if (firstMessage.message.mi === message.ack) {
                    this.#Messages.splice(0, 1);
                }
            }
        }

        if (message.mi === this.#MessageIdReceived) {
            this._OnMessage(message.m);

            const ackMessage = {
                ct: this.#CreationTime,
                ack: this.#MessageIdReceived++,
            };
            const ackJson = JSON.stringify(ackMessage);
            this.#Connection.Send(ackJson);
        }

        this.Update();
    }
}