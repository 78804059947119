import { Child } from "./Child.js";
import { float2 } from "../Engine/float2.js";
import { SyncObject } from "../Engine/SyncObject.js";

export class Player extends Child {
    #Game = null;
    #Room = null;
    #Position = new float2();
    #PositionSmoothed = new float2();
    #Layer = 1;
    #HeldObject = null;
    #HoldData = null;
    #Clicked = null;
    #PlayerId = -1;

    constructor() {
        super();
    }

    get Position() {
        return this.#Position.Clone();
    }

    SetNextPosition(value) {
        this._SendSetProperty('Position', { x: value.x, y: value.y });
    }

    get PositionSmoothed() {
        return this.#PositionSmoothed.Clone();
    }

    get Layer() {
        return this.#Layer;
    }

    set LayerNext(value) {
        this._SendSetProperty('Layer', value);
    }

    get Room() {
        return this.#Room;
    }

    SetNextRoom(value) {
        this._SendSetProperty('Room', value);
    }

    get Game() {
        return this.#Game;
    }

    set GameNext(value) {
        this._SendSetProperty('Game', value);
    }

    get IsClientPlayer() {
        return this === this.Client?.Player;
    }

    get HeldObject() {
        return this.#HeldObject;
    }

    get HoldData() {
        return this.#HoldData;
    }

    SetNextHeld(object, data) {
        this._SendSetProperty('Held', { object, data });
    }

    get Clicked() {
        return this.#Clicked;
    }

    set ClickedNext(value) {
        this._SendSetProperty('Clicked', value);
    }

    get PlayerId() {
        return this.#PlayerId;
    }

    set PlayerIdNext(value) {
        this._SendSetProperty('PlayerId', value);
    }

    get Hue() {
        let h = 0;
        let q = 0.5;
        let id = Math.max(this.PlayerId, 0);
        while (id) {
            if (id & 1) {
                h += q;
            }
            id = id >> 1;
            q *= 0.5;
        }
        return h;
    }

    Update(time, dt) {
        super.Update(time, dt);
        const interpolant = 1.0 - Math.pow(0.5, dt / 16.7);
        this.#PositionSmoothed.Interpolate(this.#PositionSmoothed, this.#Position, interpolant);
        if (this.IsClientPlayer) {
            this.Game.Update(time, dt);
        }
    }

    _ReceiveSetProperty(name, value) {
        switch (name) {
            case 'Room':
                this.#Room = value;
                break;
            case 'Game':
                this.#Game = value;
                break;
            case 'Position':
                this.#Position.Copy(value);
                break;
            case 'Held':
                this.#HeldObject = value.object;
                this.#HoldData = value.data;
                if (this.#HeldObject) {
                    this.#HeldObject.AddHeldBy(this);
                }
                break;
            case 'Clicked':
                this.#Clicked = value;
                break;
            case 'Layer':
                this.#Layer = value;
                break;
            case 'PlayerId':
                this.#PlayerId = value;
                break;
            default:
                super._ReceiveSetProperty(name, value);
                break;
        }
    }
}

Player.CursorSvg = `<svg width="24" height="24" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m17.423 11.029s-0.27855-0.5027-2.6421-4.4217c-2.3636-3.919 1.8695-6.287 4.1338-2.6549 2.2643 3.6321 2.6015 4.3535 2.6015 4.3535s1.5265 2.5264 1.8599 4.3702c0.33342 1.844-0.5231 6.2664-0.5231 6.2664l-7.0208 4.5091s-5.3762-0.14452-9.5643-4.3059c-4.188-4.1616-5.7303-5.6389-5.7303-5.6389s-0.012005-1.7562 2.2652-1.8313c2.2772-0.07509 5.3087 2.9582 5.3087 2.9582s-2.0866-3.5047-3.1494-5.2863c-0.73959-1.2398-1.6527-2.7651-2.6226-4.3734-2.3635-3.919 1.8695-6.287 4.1338-2.6549 2.2643 3.6321 5.7315 9.5914 5.7315 9.5914s-0.27855-0.5027-2.6421-4.4217c-1.1814-1.9589-0.71469-3.5303 0.33051-4.1585 1.0458-0.62863 2.6708-0.31293 3.8033 1.5036 2.2643 3.6321 2.6015 4.3535 2.6015 4.3535" fill="#fff" stroke="#000" stroke-width="1.0323"/></svg>`;

SyncObject.RegisterType(Player, 'Player');
