import { Connection } from "./Connection.js";
import { SyncView } from "./SyncView.js";

export class Server {
    Application = null;
    #Connections = new Set();

    constructor() {
    }

    get Connections() {
        return new Set(this.#Connections);
    }

    Stop() {
        this.#Connections.clear();
    }

    Update(time, dt) {
        for (const connection of this.#Connections) {
            connection.Update(time, dt);
        }
    }

    AddConnection(connection) {
        const newConnection = new ClientConnection(connection);
        newConnection.Application = this.Application;
        this.#Connections.add(newConnection);
    }

    _CreateDataForClient() {
        return null;
    }
}

const connectionTimeout = 30 * 1000;

class ClientConnection {
    Application = null;
    #Connection = null;
    #SyncView = new SyncView();
    #ClientDataNext = null;
    #LastReceiveTime = performance.now();
    #LastSendTime = performance.now();
    #KeepaliveTime = 200;

    constructor(connection) {
        this.#Connection = connection;
        this.#Connection.OnMessage.add(this.#ConnectionOnMessage.bind(this));
        this.#SyncView.OnDesync.add(this.#SyncViewOnDesync.bind(this));
    }

    get Id() {
        return this.#Connection.Id;
    }

    get ClientData() {
        return this.#SyncView.Viewed;
    }

    set ClientDataNext(value) {
        this.#SyncView.ViewedNext = value;
        this.#ClientDataNext = value;
    }

    get TimeSinceMessage() {
        const now = performance.now();
        const ms = now - this.#LastReceiveTime;
        return ms;
    }

    get IsTimedOut() {
        return this.TimeSinceMessage > connectionTimeout;
    }

    Update(time, dt) {
        this.#Connection.Update(time);
        const keepalive = (performance.now() - this.#LastSendTime) > this.#KeepaliveTime;
        const message = this.#SyncView.GetChanges(time, keepalive);
        const timedOut = this.IsTimedOut;
        const haveMessage = !!message;
        if (haveMessage && !timedOut) {
            //console.log('Server send', message, this.#Connection.constructor.name);
            const json = JSON.stringify(message);
            const connection = this.#Connection;
            const simulateLag = false;
            if (simulateLag) {
                setTimeout(() => connection.Send(json), 200 + Math.random() * 100);
            }
            else {
                connection.Send(json);
            }
            this.#LastSendTime = performance.now();
        }
    }

    #ConnectionOnMessage(json) {
        this.Application.Pump();
        this.#LastReceiveTime = performance.now();
        const message = JSON.parse(json);
        if (message) {
            //console.log('Server recieve', message, this.#Connection.constructor.name);
            this.#SyncView.ApplyChanges(message);
        }
    }

    #SyncViewOnDesync(view) {
        // Client is newer than this server. Reset and resend everything.
        view.Reset();
        this.#SyncView.ViewedNext = this.#ClientDataNext;
    }
}