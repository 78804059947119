import { float2 } from "../Engine/float2.js";
import { float2x3 } from "../Engine/float2x3.js";
import { Element } from "./Element.js";
import { Room } from "./Room.js";
import { SyncObject } from "../Engine/SyncObject.js";

export class Body extends Element {
    #HeldBy = new Map();
    #Position = new float2();
    #Rotation = 0;
    #PositionSmoothed = new float2();
    #RotationSmoothed = 0;
    #Matrix = new float2x3();
    #MatrixDirty = true;
    #PositionVelocity = new float2();
    #RotationVelocity = 0;
    #Physics = null;
    #Initialised = false;
    #Scale = new float2();
    #Fixtures = null;
    #Dynamic = null;
    #HeldPrevious = null;
    #PickedUp = false;
    #Dropped = false;
    #ContactSpeed = 0;
    #ContactSpeedPrevious = 0;
    #MaxContactSpeed = 0;
    #Speed = 0;
    #_Hit = 0;
    #Contacts = new Set();
    static #PhysicsToBody = new WeakMap();

    static GetBodyFromPhysics(physics) {
        return this.#PhysicsToBody.get(physics);
    }

    constructor() {
        super();
    }

    _OnClientChanged(value, oldValue) {
        super._OnClientChanged(value, oldValue);
        this.#UpdateSvg();
    }

    get Position() {
        return this.#Position.Clone();
    }

    SetNextPosition(value) {
        this._SendSetProperty('Position', { x: value.x, y: value.y });
    }

    get PositionSmoothed() {
        return this.#PositionSmoothed.Clone();
    }

    get Rotation() {
        return this.#Rotation;
    }

    SetNextRotation(value) {
        this._SendSetProperty('Rotation', value);
    }

    get RotationSmoothed() {
        return this.#RotationSmoothed;
    }

    get Matrix() {
        this.#UpdateMatrix();
        return this.#Matrix.Clone();
    }

    get MatrixInverse() {
        const rotateInverse = new float2x3().MakeRotation(-this.Rotation);
        const translateInverse = new float2x3()
            .Set(0, 2, -this.Position.x)
            .Set(1, 2, -this.Position.y);
        return rotateInverse.Multiply(translateInverse);
    }

    get PositionVelocity() {
        return this.#PositionVelocity.Clone();
    }

    SetNextPositionVelocity(value) {
        this._SendSetProperty('PositionVelocity', { x: value.x, y: value.y });
    }

    get RotationVelocity() {
        return this.#RotationVelocity;
    }

    SetNextRotationVelocity(value) {
        this._SendSetProperty('RotationVelocity', value);
    }

    get Physics() {
        return this.#Physics;
    }

    get Initialised() {
        return this.#Initialised;
    }

    get HeldBy() {
        return new Set(this.#HeldBy.keys());
    }

    get Held() {
        return this.#HeldBy.size > 0;
    }

    get PickedUp() {
        return this.#PickedUp;
    }

    get Dropped() {
        return this.#Dropped;
    }

    get Speed() {
        return this.#Speed;
    }

    set SpeedNext(value) {
        this._SendSetProperty('Speed', value);
    }

    get ContactSpeed() {
        return this.#ContactSpeed;
    }

    set ContactSpeedNext(value) {
        this._SendSetProperty('ContactSpeed', value);
    }

    get Hit() {
        return this.#_Hit;
    }

    set #Hit(value) {
        this.#_Hit = value;
    }

    IsInContact(body) {
        return this.#Contacts.has(body);
    }

    AddContactNext(body) {
        this._SendMessage({
            type: 'AddContact',
            value: body,
        });
    }

    RemoveContactNext(body) {
        this._SendMessage({
            type: 'RemoveContact',
            value: body,
        });
    }

    InitialiseNext(translate, rotate, scale, fixtures, dynamic) {
        translate = { x: translate.x, y: translate.y };
        scale = { x: scale.x, y: scale.y };
        dynamic = !!dynamic;
        this._SendSetProperty(
            'Init',
            {
                translate,
                rotate,
                scale,
                fixtures,
                dynamic,
            });
    }

    AddHeldBy(player) {
        if (!this.#HeldBy.has(player)) {
            this.#HeldBy.set(player, null);
        }
    }

    Update(time, dt) {
        super.Update(time, dt);

        const interpolant = 1.0 - Math.pow(0.5, dt / 16.7);
        this.#PositionSmoothed.Interpolate(this.#PositionSmoothed, this.#Position, interpolant);
        this.#RotationSmoothed = this.#RotationSmoothed * (1 - interpolant) + this.#Rotation * interpolant;

        this.#UpdateSvg();

        if (!this.Client) {
            if (this.#Initialised) {
                for (const player of this.#HeldBy.keys()) {
                    let constraint = this.#HeldBy.get(player);
                    if (player.HeldObject !== this) {
                        if (constraint) {
                            const world = this.Room.World;
                            world.destroyJoint(constraint);
                        }
                        this.#HeldBy.delete(player);
                    }
                    else {
                        const world = this.Room.World;
                        const playerPos = player.Position.MultiplyScalar(1 / Room.pixelsPerMeter);
                        const playerPosPlanck = planck.Vec2(playerPos);
                        if (!constraint) {
                            constraint = planck.MouseJoint(
                                {
                                    dampingRatio: 1,
                                    frequencyHz: 5,
                                    maxForce: 1e2,
                                },
                                this.Room.WorldBody,
                                this.#Physics,
                                planck.Vec2().set(playerPos));
                            constraint = world.createJoint(constraint);
                            this.#HeldBy.set(player, constraint);
                        }
                        constraint.setTarget(playerPosPlanck);
                    }
                }

                const planckPosition = new float2().Copy(this.#Physics.getPosition());
                planckPosition.MultiplyScalar(Room.pixelsPerMeter);
                const posDistance = this.Position.Subtract(planckPosition).Length();
                if (posDistance > 1e-6) {
                    this.SetNextPosition(planckPosition);
                }
                const angle = this.#Physics.getAngle();
                const angleDistance = Math.abs(this.Rotation - angle);
                if (angleDistance > 1e-6) {
                    this.SetNextRotation(angle);
                }

                const newSpeed = this.#Physics.getLinearVelocity().length() * Room.pixelsPerMeter;
                const oldSpeed = this.Speed;
                if (Math.abs(newSpeed - oldSpeed) > 0.01) {
                    this.SpeedNext = newSpeed;
                }
            }
            else if (this.#Fixtures) {
                this.#Initialise();
            }

            if (this.#MaxContactSpeed > 0) {
                this.ContactSpeedNext = this.#MaxContactSpeed;
            }
            this.#MaxContactSpeed = 0;
        }
        else {
            for (const player of this.#HeldBy.keys()) {
                if (player.HeldObject !== this) {
                    this.#HeldBy.delete(player);
                }
            }

            if (this.#ContactSpeed !== this.#ContactSpeedPrevious) {
                this.#Hit = this.#ContactSpeed;
                this.#ContactSpeedPrevious = this.#ContactSpeed;
            }
            else {
                this.#Hit = 0;
            }
        }

        const held = this.Held;
        if (this.#HeldPrevious !== null) {
            const changed = this.#HeldPrevious != held;
            this.#PickedUp = changed && held;
            this.#Dropped = changed && !held;
        }
        this.#HeldPrevious = held;
    }

    SetFilter(filterBits) {
        if (this.#Physics) {
            for (let f = this.#Physics.getFixtureList(); f; f = f.getNext()) {
                f.setFilterData({
                    maskBits: filterBits | 0,
                    categoryBits: f.getFilterCategoryBits(),
                    groupIndex: f.getFilterGroupIndex(),
                });
            }
        }
    }

    _ReceiveMessage(message) {
        switch (message.type) {
            case 'AddContact':
                this.#Contacts.add(message.value);
                break;
            case 'RemoveContact':
                this.#Contacts.delete(message.value);
                break;
            default:
                super._ReceiveMessage(message);
                break;
        }
    }

    _ReceiveSetProperty(name, value) {
        switch (name) {
            case 'Position':
                this.#Position.Copy(value);
                this.#MatrixDirty = true;
                break;
            case 'PositionVelocity':
                this.#PositionVelocity.Copy(value);
                break;
            case 'Rotation':
                this.#Rotation = value;
                this.#MatrixDirty = true;
                break;
            case 'RotationVelocity':
                this.#RotationVelocity = value;
                break;
            case 'Init':
                this.#Position.Copy(value.translate);
                this.#PositionSmoothed.Copy(value.translate);
                this.#Rotation = value.rotate;
                this.#RotationSmoothed = value.rotate;
                this.#Scale.Copy(value.scale);
                this.#Fixtures = value.fixtures;
                this.#Dynamic = value.dynamic;
                break;
            case 'ContactSpeed':
                this.#ContactSpeed = value;
                break;
            case 'Speed':
                this.#Speed = value;
                break;
            default:
                super._ReceiveSetProperty(name, value);
                break;
        }
    }

    _OnPointerDown(event) {
        super._OnPointerDown(event);
        this._OnHold(event);
    }

    _OnPointerUp(event) {
        super._OnPointerUp(event);
        this._OnDrop(event);
    }

    _OnHold(event) {
        event.Capture();
        const player = this.Client.Player;
        player.SetNextHeld(this);
    }

    _OnDrop(event) {
        event.Release();
        const player = this.Client.Player;
        player.SetNextHeld(null);
    }

    #UpdateMatrix() {
        if (this.#MatrixDirty) {
            this.#Matrix.MakeRotation(this.#Rotation);
            this.#Matrix.Set(0, 2, this.#Position.x);
            this.#Matrix.Set(1, 2, this.#Position.y);
            this.#MatrixDirty = false;
        }
    }

    #UpdateSvg() {
        if (this.Client) {
            if (this.Svg) {
                const translate = this.Svg.viewportElement.createSVGMatrix().translate(this.#PositionSmoothed.x, this.#PositionSmoothed.y);
                const rotate = this.Svg.viewportElement.createSVGMatrix().rotate(180 * this.#RotationSmoothed / Math.PI);
                const scale = this.Svg.viewportElement.createSVGMatrix();
                scale.a = this.#Scale.x;
                scale.d = this.#Scale.y;
                const bodyToWorld = translate.multiply(rotate).multiply(scale);
                const parent = this.Svg.parentElement;
                let worldToParent = this.Svg.viewportElement.createSVGMatrix();
                const viewportElement = this.Svg.viewportElement;
                {
                    let current = parent;
                    while (current && !this.Room.IsLayer(current) && current != viewportElement) {
                        let objectMatrix = viewportElement.createSVGMatrix();
                        const components = current.transform.baseVal
                        for (let ci = 0; ci < components.length; ++ci) {
                            const component = components.getItem(ci);
                            const componentMatrix = component.matrix;
                            objectMatrix = objectMatrix.multiply(componentMatrix);
                        }
                        worldToParent = objectMatrix.multiply(worldToParent);
                        current = current.parentElement;
                    }
                }
                worldToParent = worldToParent.inverse();
                const bodyToParent = worldToParent.multiply(bodyToWorld);
                const transform = this.Svg.transform.baseVal;
                if (transform.length !== 1) {
                    if (transform.length === 0) {
                        const item = this.Svg.viewportElement.createSVGTransform();
                        item.setTranslate(0, 0);
                        transform.appendItem(item);
                    }
                }
                transform.getItem(0).setMatrix(bodyToParent);
            }
        }
    }

    #Initialise() {

        if (!this.Client) {
            const fixtures = this.#Fixtures;
            const dynamic = this.#Dynamic;
            const bodyTranslateInverse = new float2x3().MakeTranslation(this.#Position.Clone().MultiplyScalar(-1 / Room.pixelsPerMeter));
            const bodyRotateInverse = new float2x3().MakeRotation(-this.#Rotation);
            const worldToBody = bodyRotateInverse.Multiply(bodyTranslateInverse);
            const world = this.Room.World;
            this.#Physics = world.createBody();
            Body.#PhysicsToBody.set(this.#Physics, this);
            if (dynamic) {
                this.#Physics.setDynamic();
            }
            this.#Physics.setAngularDamping(1e0);
            const slop = planck.internal.Settings.linearSlop;
            for (const fixture of fixtures) {
                const typeMask = fixture.typeMask;
                const filterMask = fixture.filterMask;
                const isSensor = !!fixture.isSensor;
                switch (fixture.type) {
                    case 'box':
                        {
                            const width = fixture.width;
                            const height = fixture.height;
                            const worldPos = new float2().Copy(fixture.position).MultiplyScalar(1 / Room.pixelsPerMeter);
                            const worldRot = fixture.rotation ?? 0;
                            const localRot = worldRot - this.#Rotation;
                            const localPos = worldPos.Clone().Multiply2x3(worldToBody);
                            const halfWidth = (width / 2) / Room.pixelsPerMeter - slop * 2;
                            const halfHeight = (height / 2) / Room.pixelsPerMeter - slop * 2;
                            const box = planck.Box(halfWidth, halfHeight, localPos, localRot);
                            this.#Physics.createFixture(
                                box,
                                {
                                    density: 1,
                                    friction: 0.5,
                                    restitution:0.2,
                                    filterCategoryBits: typeMask,
                                    filterMaskBits: filterMask,
                                    isSensor,
                                });
                        }
                        break;
                    case 'circle':
                        {
                            const radius = (fixture.radius / Room.pixelsPerMeter) - slop;
                            const worldPos = new float2().Copy(fixture.position).MultiplyScalar(1 / Room.pixelsPerMeter);
                            const localPos = worldPos.Clone().Multiply2x3(worldToBody);
                            const circle = planck.Circle(localPos, radius);
                            this.#Physics.createFixture(
                                circle,
                                {
                                    density: 1,
                                    friction: 0.5,
                                    restitution: 0.2,
                                    filterCategoryBits: typeMask,
                                    filterMaskBits: filterMask,
                                    isSensor,
                                });
                        }
                        break;
                    case 'path':
                        {
                            const points = fixture.points.map(point => planck.Vec2(point[0] / Room.pixelsPerMeter, point[1] / Room.pixelsPerMeter));
                            const shape = planck.Polygon(points);
                            this.#Physics.createFixture(
                                shape,
                                {
                                    density: 1,
                                    friction: 0.5,
                                    restitution: 0.2,
                                    filterCategoryBits: typeMask,
                                    filterMaskBits: filterMask,
                                    isSensor,
                                });
                        }
                        break;
                    default:
                        console.error('Unknown fixture type', fixture.type);
                        break;
                }
            }
            const bodyX = this.#Position.x / Room.pixelsPerMeter;
            const bodyY = this.#Position.y / Room.pixelsPerMeter;
            this.#Physics.setTransform(planck.Vec2(bodyX, bodyY), this.#Rotation);
        }

        this.#Initialised = true;
    }

    _Contact(speed) {
        this.#MaxContactSpeed = Math.max(this.#MaxContactSpeed, speed);
    }

    _ClassifyMessages(messageMap) {
        super._ClassifyMessages(messageMap);

        const lastChild = new WeakMap();

        for (const message of messageMap.keys()) {
            switch (message.type) {
                case 'AddContact':
                case 'RemoveContact':
                    const child = message.value;
                    if (lastChild.has(child)) {
                        messageMap.set(message, false);
                    }
                    lastChild.set(child, message);
                    messageMap.set(message, true);
                    break;
                default:
                    break;
            }
        }
    }

}

SyncObject.RegisterType(Body, 'Body');
