import { Connection } from "./Connection.js";

export class WebsocketConnection extends Connection {
    static #Contexts = new Map();

    #Context = null;
    #remoteId = null;
    #remoteIdBytes = null;

    constructor() {
        super();
    }

    get Id() {
        return this.#remoteId;
    }

    get LocalId() {
        return this.#Context.localId;
    }

    Listen(localId) {
        if (!WebsocketConnection.#Contexts.has(localId)) {
            const context = new WebsocketContext(localId);
            WebsocketConnection.#Contexts.set(localId, context);
        }
        this.#Context = WebsocketConnection.#Contexts.get(localId);
    }

    Accept() {
        let result = null;
        if (this.#Context.NewConnections.length > 0) {
            result = this.#Context.NewConnections[0];
            this.#Context.NewConnections.splice(0, 1);
        }
        return result;
    }

    Open(localId, remoteId) {
        this.Listen(localId);
        this.#remoteId = remoteId;
        this.#remoteIdBytes = ToBytes(remoteId);
        this.#Context.RemoteIdToConnection.set(remoteId, this);
    }

    Send(message) {
        const encoder = new TextEncoder();
        const messageBytes = encoder.encode(message);
        const bytes = new Uint8Array(messageBytes.length + 17);
        bytes[0] = PacketType.Message;
        bytes.set(this.#Context.localIdBytes, 1);
        bytes.set(this.#remoteIdBytes, 9);
        bytes.set(messageBytes, 17);
        const websocket = this.#Context.Websocket;
        if (websocket?.readyState === WebSocket.OPEN) {
            websocket.send(bytes);
        }
    }

    GetServerTime(localTime) {
        return this.#Context.GetServerTime(localTime);
    }

    Close() {
        this.#Context.Websocket?.close();
    }
}

class WebsocketContext {
    Websocket = null;
    localId = null;
    localIdBytes = null;
    RemoteIdToConnection = new Map();
    LocalToServerTime = undefined;
    NextGetServerTime = -1;
    NextGetServerTimeStep = 16;
    NewConnections = [];
    #WebsocketOnOpenBind;
    #WebsocketOnMessageBind;
    #WebsocketOnErrorBind;
    #WebsocketOnCloseBind;

    constructor(localId) {
        this.#WebsocketOnOpenBind = this.#WebsocketOnOpen.bind(this);;
        this.#WebsocketOnMessageBind = this.#WebsocketOnMessage.bind(this);;
        this.#WebsocketOnErrorBind = this.#WebsocketOnError.bind(this);;
        this.#WebsocketOnCloseBind = this.#WebsocketOnClose.bind(this);;

        this.localId = localId;
        this.localIdBytes = ToBytes(localId);
        this.#Reset();
    }

    GetServerTime(localTime) {
        const websocket = this.Websocket;
        if (localTime > this.NextGetServerTime) {
            if (websocket?.readyState === WebSocket.OPEN) {
                const doubleArray = new Float64Array(1);
                doubleArray[0] = localTime;
                const doubleAsBytes = new Uint8Array(doubleArray.buffer, 0, doubleArray.byteLength);
                const requestTimePacket = new Uint8Array(9);
                requestTimePacket[0] = PacketType.Time;
                requestTimePacket.set(doubleAsBytes, 1);
                websocket.send(requestTimePacket);
            }
            this.NextGetServerTime = localTime + this.NextGetServerTimeStep;
            this.NextGetServerTimeStep *= 2;
            this.NextGetServerTimeStep = Math.min(this.NextGetServerTimeStep, 5000);
        }

        if (this.LocalToServerTime === undefined) {
            return undefined;
        }

        return localTime + this.LocalToServerTime;
    }

    #WebsocketOnOpen() {
        console.log('Connected to server');
        const websocket = this.Websocket;
        if (websocket?.readyState === WebSocket.OPEN) {
            const setIdPacket = new Uint8Array(9);
            setIdPacket[0] = PacketType.Message;
            setIdPacket.set(this.localIdBytes, 1);
            websocket.send(setIdPacket);
        }
    }

    #WebsocketOnMessage(event) {
        const arraybuffer = event.data;
        const message = new Uint8Array(arraybuffer);
        const packetType = message[0];
        switch (packetType) {
            case PacketType.Message:
                {
                    const remoteId = this.#ToId(message, 1, 8);
                    let connection = this.RemoteIdToConnection.get(remoteId);
                    if (!connection) {
                        connection = new WebsocketConnection();
                        connection.Open(this.localId, remoteId);
                        this.NewConnections.push(connection);
                    }
                    const decoder = new TextDecoder();
                    const oldOffset = message.byteOffset;
                    const newOffset = oldOffset + 17;
                    const oldLength = message.byteLength;
                    const newLength = oldLength - 17;
                    const payloadBytes = new Uint8Array(message.buffer, newOffset, newLength);
                    const payloadString = decoder.decode(payloadBytes);
                    connection._OnMessage(payloadString);
                }
                break;
            case PacketType.Time:
                {
                    const doubleBuffer = new Float64Array(1);
                    const doubleBytes = new Uint8Array(doubleBuffer.buffer, 0);
                    for (let i = 0; i < 8; ++i) {
                        doubleBytes[i] = message[i + 1];
                    }
                    const newLocalToServerTime = doubleBuffer[0];
                    if (this.LocalToServerTime === undefined || newLocalToServerTime > this.LocalToServerTime) {
                        this.LocalToServerTime = newLocalToServerTime;
                    }
                }
                break;
            default:
                console.error('Unknown packet type:', packetType);
                break;
        }
    }

    #WebsocketOnError(e) {
        console.error('Websocket error', e)
        this.#Reset();
    }

    #WebsocketOnClose() {
        console.warn('Websocket closed')
        this.#Reset();
    }

    #Reset() {
        if (this.Websocket) {
            this.Websocket.removeEventListener('open', this.#WebsocketOnOpenBind);
            this.Websocket.removeEventListener('message', this.#WebsocketOnMessageBind);
            this.Websocket.removeEventListener('error', this.#WebsocketOnErrorBind);
            this.Websocket.removeEventListener('close', this.#WebsocketOnCloseBind);
            this.Websocket.close();
            this.Websocket = null;
            setTimeout(() => this.#Reset(), 1000);
            return;
        }
        const wsProtocol = location.protocol === 'https:' ? 'wss' : 'ws';
        const url = `${wsProtocol}://${location.host}/api/ws`;
        this.Websocket = new WebSocket(url);
        this.Websocket.binaryType = 'arraybuffer';
        this.Websocket.addEventListener('open', this.#WebsocketOnOpenBind);
        this.Websocket.addEventListener('message', this.#WebsocketOnMessageBind);
        this.Websocket.addEventListener('error', this.#WebsocketOnErrorBind);
        this.Websocket.addEventListener('close', this.#WebsocketOnCloseBind);
    }

    #ToId(bytes, offset, count) {
        const lookup = [
            '0',
            '1',
            '2',
            '3',
            '4',
            '5',
            '6',
            '7',
            '8',
            '9',
            'a',
            'b',
            'c',
            'd',
            'e',
            'f',
        ];
        let result = "";
        for (let i = 0; i < count; ++i) {
            const byte = bytes[i + offset];
            for (let j = 0; j < 2; ++j) {
                const nybble = (byte >> (j * 4)) & 0xf;
                result += lookup[nybble];
            }
        }
        return result;
    }
}

const PacketType = {
    Message: 0,
    Time: 1,
};

function ToBytes(id) {
    const lookup = {
        '0': 0,
        '1': 1,
        '2': 2,
        '3': 3,
        '4': 4,
        '5': 5,
        '6': 6,
        '7': 7,
        '8': 8,
        '9': 9,
        'a': 10,
        'b': 11,
        'c': 12,
        'd': 13,
        'e': 14,
        'f': 15,
    };
    const charCount = id.length;
    const byteCount = (charCount + 1) / 2;
    const bytes = new Uint8Array(byteCount);
    for (let i = 0; i < byteCount; ++i) {
        let byte = 0;
        for (let j = 0; (j < 2) && (i * 2 + j < charCount); ++j) {
            const char = id.charAt(i * 2 + j);
            const nybble = lookup[char];
            byte += nybble << (4 * j);
        }
        bytes[i] = byte;
    }
    return bytes;
}
