import { SyncObject } from "./SyncObject.js";

export class SyncView extends SyncObject {
    #viewed = null;
    #objectToState = new WeakMap();
    #idToObject = new Map();
    #nextId = 1;
    #pendingMessages = new Map();
    #objectsToUpdate = new Set();
    #OnNewObject = new Set();
    #ApplyingChanges = false;
    #LatestReceived = -1;
    #ToAcknowledge = [];
    #LatestSent = -1;
    #OpenTime = undefined; // Not null because want comparisons to return false.
    #OnDesync = new Set();

    Reset() {
        this.#Reset();
    }

    constructor() {
        super();
        this.#Reset();
    }

    get Viewed() {
        return this.#viewed;
    }

    set ViewedNext(value) {
        this._SendSetProperty('Viewed', value);
    }

    get OnNewObject() {
        return this.#OnNewObject;
    }

    get OnDesync() {
        return this.#OnDesync;
    }

    GetChanges(externalTime = null, keepalive = false) {
        this.#ApplyPendingMessages();

        const minTime = this.#LatestSent + 1; // Make sure to increase time each packet.
        const rawTime = Math.floor(externalTime ?? performance.now());
        const time = Math.max(rawTime, minTime);
        this.#OpenTime = this.#OpenTime ?? time;
        const open = this.#OpenTime;
        let result = {
            open,
            time,
            messages: [],
            ack: this.#ToAcknowledge.slice(),
        };
        this.#ToAcknowledge.length = 0;
        const messages = this.#pendingMessages.keys();
        const deleteMessages = new Set();
        const ping = 1000;
        const resendCutoff = time - ping;
        for (const message of messages) {
            const pendingState = this.#pendingMessages.get(message);
            if (pendingState.time === undefined || pendingState.time < resendCutoff) {
                pendingState.time = time;
                const { object, serialized } = pendingState;
                if (object) {
                    if (object.HasMessage(message)) {
                        const objectState = this.#objectToState.get(object);
                        const id = objectState.id;
                        result.messages.push({
                            type: 'obj',
                            id,
                            message: serialized,
                        });
                    }
                    else {
                        deleteMessages.add(message);
                    }
                }
                else {
                    result.messages.push(message);
                }
            }
        }
        for (const message of deleteMessages) {
            this.#pendingMessages.delete(message);
        }
        // Todo: change ack to message.
        if (result.messages.length === 0 && result.ack.length === 0 && !keepalive) {
            result = null;
        }
        else {
            this.#LatestSent = time;
        }
        return result;
    }

    ApplyChanges(packet = undefined) {
        try {
            this.#ApplyingChanges = true;

            if (!packet) {
                return;
            }

            if (packet.time <= this.#LatestReceived || packet.time < this.#OpenTime) {
                return;
            }

            if (packet.open > this.#OpenTime) {
                for (const handler of this.#OnDesync) {
                    try {
                        handler.call(this, this);
                    }
                    catch (e) {
                        console.error('Error in OnDesync handler', e);
                    }
                }
                this.#OpenTime = packet.open;
                return;
            }
            else if (packet.open < this.#OpenTime || this.#OpenTime === undefined) {
                return;
            }

            this.#LatestReceived = packet.time;

            let acknowledge = packet.messages.length > 0;

            for (const viewMessage of packet.messages) {
                switch (viewMessage.type) {
                    case 'new':
                        const { name, id } = viewMessage;
                        if (!this.#idToObject.has(id)) {
                            const object = SyncObject.CreateType(name);
                            this.#AddObject(object, id);
                        }
                        break;
                    case 'obj':
                        {
                            const { id, message } = viewMessage;
                            const deserialized = this.#DeserializeMessage(message);
                            const object = this.#idToObject.get(id);
                            if (object) {
                                object._ApplyPendingMessage(deserialized);
                            }
                            else {
                                acknowledge = false;
                            }
                        }
                        break;
                    default:
                        console.error('Unknown message type:', message.type, message);
                        break;
                }
            }

            if (acknowledge) {
                this.#ToAcknowledge.push(packet.time);
            }

            const acknowledged = new Set(packet.ack);
            const removePending = new Set();
            for (const message of this.#pendingMessages.keys()) {
                const pendingState = this.#pendingMessages.get(message);
                if (pendingState.time !== undefined && acknowledged.has(pendingState.time)) {
                    removePending.add(message);
                }
            }
            for (const message of removePending) {
                this.#pendingMessages.delete(message);
            }
        }
        finally {
            this.#ApplyingChanges = false;
        }
    }

    _ReceiveSetProperty(name, value) {
        switch (name) {
            case 'Viewed':
                this.#viewed = value;
                break;
            default:
                super._ReceiveSetProperty(name, value);
                break;
        }
    }

    #Reset() {
        this.#viewed = null;
        this.#objectToState = new WeakMap();
        this.#idToObject = new Map();
        this.#pendingMessages = new Map();
        this.#objectsToUpdate = new Set();
        this.#ApplyingChanges = false;
        this.#LatestReceived = -1;
        this.#ToAcknowledge = [];
        this.#LatestSent = -1;
        this.#nextId = 0;
        this.#AddObject(this);
    }

    #AddObject(object, id = undefined) {
        if (!this.#objectToState.has(object)) {
            const idDefined = id !== undefined;
            id = id ?? this.#nextId++;
            this.#objectToState.set(object, { id });
            this.#idToObject.set(id, object);
            object.OnApplyMessage.add(this.#ObjectOnApplyMessage.bind(this));
            object.OnSendMessage.add(this.#ObjectOnSendMessage.bind(this));
            this.#objectsToUpdate.add(object);
            if (!idDefined) {
                const typeName = SyncObject.GetTypeName(object);
                if (!typeName) {
                    console.error('Failed to get type name of', object);
                }
                this.#pendingMessages.set(
                    {
                        type: 'new',
                        id,
                        name: typeName,
                    },
                    {});
                const messages = object.AppliedMessages;
                for (const message of messages) {
                    this.#ObjectOnApplyMessage(object, message);
                }
            }
            for (const onNewObject of this.#OnNewObject) {
                try {
                    onNewObject.call(undefined, object);
                }
                catch (err) {
                    console.error(err);
                }
            }
        }
        return this.#objectToState.get(object).id;
    }

    #ObjectOnApplyMessage(object, message) {
        if (this.#ApplyingChanges) {
            // Is an incoming message, don't send it back.
            return;
        }
        const serialized = this.#SerializeMessage(message);
        this.#pendingMessages.set(message, { object, serialized });
        this.#objectsToUpdate.add(object);
    }

    #ObjectOnSendMessage(object) {
        this.#objectsToUpdate.add(object);
    }

    #ApplyPendingMessages() {
        while (this.#objectsToUpdate.size > 0) {
            const object = this.#objectsToUpdate.values().next().value;
            object.ApplyPendingMessages();
            this.#objectsToUpdate.delete(object);
        }
    }

    #SerializeMessage(message) {
        if (message) {
            if (typeof message === 'object') {
                if (message instanceof SyncObject) {
                    const objectId = this.#AddObject(message);
                    return {
                        '#refid': objectId,
                    };
                }
                else if (message instanceof Array) {
                    const copy = [];
                    for (let i = 0; i < message.length; ++i) {
                        copy[i] = this.#SerializeMessage(message[i]);
                    }
                    return copy;
                }
                else {
                    const copy = {};
                    for (const key in message) {
                        const value = message[key];
                        copy[key] = this.#SerializeMessage(value);
                    }
                    return copy;
                }
            }
        }
        return message;
    }

    #DeserializeMessage(message) {
        if (message) {
            if (typeof message === 'object') {
                const keys = Object.keys(message);
                if (keys.length === 1 && keys[0] === '#refid') {
                    const id = message['#refid'];
                    const object = this.#idToObject.get(id);
                    return object;
                }
                else if (message instanceof Array) {
                    const copy = [];
                    for (let i = 0; i < message.length; ++i) {
                        copy[i] = this.#DeserializeMessage(message[i]);
                    }
                    return copy;
                }
                else {
                    const copy = {};
                    for (const key of keys) {
                        copy[key] = this.#DeserializeMessage(message[key]);
                    }
                    return copy;
                }
            }
        }
        return message;
    }
}

SyncObject.RegisterType(SyncView, 'SyncView');
