import { SyncView } from "./SyncView.js";
import { WebsocketConnection } from "./WebsocketConnection.js";

export class Client {
    #Connection = null;
    #View = new SyncView();
    #LastSendTime = performance.now();

    constructor(connection) {
        this.#Connection = connection;
        connection.OnMessage.add(this.#OnMessage.bind(this));
        this.#View.OnDesync.add(this.#SyncViewOnDesync.bind(this));
    }

    get ClientData() {
        return this.#View.Viewed;
    }

    get OnNewObject() {
        return this.#View.OnNewObject;
    }

    Update(time, dt) {
        const keepaliveDuration = 100;
        const timeSinceSend = performance.now() - this.#LastSendTime;
        const keepalive = timeSinceSend > keepaliveDuration;

        this.#Connection.Update(time);

        const message = this.#View.GetChanges(time, keepalive);

        if (message) {
            //console.log('Client send', message);
            const json = JSON.stringify(message);
            const simulateLag = false;
            const connection = this.#Connection;
            if (simulateLag) {
                setTimeout(() => connection.Send(json), 200 + Math.random() * 100);
            }
            else {
                connection.Send(json);
            }
            this.#LastSendTime = performance.now();
        }
    }

    Stop() {

    }

    _Reset() {
    }

    #OnMessage(json) {
        const message = JSON.parse(json);
        if (message) {
            //console.log('Client receive', message);
            this.#View.ApplyChanges(message);
        }
    }

    #SyncViewOnDesync(view) {
        // Server is newer than this client. Clear out all state.
        this._Reset();
        this.#View.ClearPendingAndApplied();
        this.#View.Reset();
    }
}
