import { Element } from "./Element.js";
import { SyncObject } from "../Engine/SyncObject.js";

export class Button extends Element {
    #HeldBy = new Map();
    #PreviousHeld = null;
    #Pressed = false;
    #Released = false;

    constructor() {
        super();
    }

    get Held() {
        return this.#HeldBy.size > 0;
    }

    get Pressed() {
        return this.#Pressed;
    }

    get Released() {
        return this.#Released;
    }

    AddHeldBy(player) {
        this.#HeldBy.set(player, null);
    }

    Update(time, dt) {
        super.Update(time, dt);
        for (const player of this.#HeldBy.keys()) {
            this.#HeldBy.get(player);
            if (player.HeldObject !== this) {
                this.#HeldBy.delete(player);
            }
        }
        if (this.Client) {
            const element = this.Svg;
            if (element) {
                let scale = 1;
                if (this.Held) {
                    scale = 0.9;
                }
                const transform = element.transform.baseVal;
                if (transform.length !== 1) {
                    const item = element.viewportElement.createSVGTransform();
                    item.setTranslate(0, 0);
                    transform.appendItem(item);
                    transform.consolidate();
                }
                transform.getItem(0).setScale(scale, scale);
            }
        }

        const held = this.Held
        if (this.#PreviousHeld !== null) {
            const changed = held != this.#PreviousHeld;
            this.#Pressed = changed && held;
            this.#Released = changed && !held;
        }
        this.#PreviousHeld = held;
    }

    _OnPointerDown(event) {
        event.Capture();
        const player = this.Client.Player;
        player.SetNextHeld(this);
    }

    _OnPointerUp(event) {
        event.Release();
        const player = this.Client.Player;
        player.SetNextHeld(null);
    }
}

SyncObject.RegisterType(Button, 'Button');
