import { Child } from "./Child.js";
import { SyncObject } from "../Engine/SyncObject.js";
//  0
// 1 2
//  3
// 4 5
//  6
//
function MakeCode(segments) {
    let result = 0;
    for (let i of segments) {
        result = result | (1 << i);
    }
    return result;
}
const codes = {
    '0': MakeCode([0, 1, 2, 4, 5, 6]),
    '1': MakeCode([2, 5]),
    '2': MakeCode([0, 2, 3, 4, 6]),
    '3': MakeCode([0, 2, 3, 5, 6]),
    '4': MakeCode([1, 2, 3, 5]),
    '5': MakeCode([0, 1, 3, 5, 6]),
    '6': MakeCode([0, 1, 3, 4, 5, 6]),
    '7': MakeCode([0, 2, 5]),
    '8': MakeCode([0, 1, 2, 3, 4, 5, 6]),
    '9': MakeCode([0, 1, 2, 3, 5, 6]),
};

export class SevenSegment extends Child {
    #Room = null;
    #ElementId = null;
    #_Element = null;
    #Character = '8';
    #CharacterPrevious = null;
    #ColorSeed = 0;
    #ColorSeedPrevious = -1;

    constructor() {
        super();
    }

    get Room() {
        return this.#Room;
    }

    set RoomNext(value) {
        this._SendSetProperty('Room', value);
    }

    get ElementId() {
        return this.#ElementId;
    }

    set ElementIdNext(value) {
        this._SendSetProperty('ElementId', value);
    }

    get Element() {
        if (!this.#_Element && this.#Room && this.#ElementId) {
            const svg = this.#Room.Svg;
            if (svg) {
                this.#Element = svg.getElementById(this.#ElementId);
            }
        }
        return this.#_Element;
    }

    set #Element(value) {
        this.#_Element = value;
    }

    get Character() {
        return this.#Character;
    }

    set CharacterNext(value) {
        this._SendSetProperty('Character', value);
    }

    get ColorSeed() {
        return this.#ColorSeed;
    }

    set ColorSeedNext(value) {
        this._SendSetProperty('ColorSeed', value);
    }

    Update(time, dt) {
        super.Update(time, dt);
        if (this.Client) {
            const element = this.Element;
            if (element) {
                const changed
                    = this.#Character !== this.#CharacterPrevious
                    || this.#ColorSeed !== this.#ColorSeedPrevious;
                if (changed) {
                    let colorCode = this.#ColorSeed;
                    const code = codes[this.#Character] || 0;
                    for (const child of element.children) {
                        const id = child.id;
                        const segId = id.charCodeAt(1) - '0'.charCodeAt(0);
                        const visible = !!(code & (1 << segId));
                        let red = (((((colorCode / 3) | 0) % 3) * 0xFF) / 2) | 0;
                        if (visible) {
                            red = Math.min(red, 0x7F);
                        }
                        else {
                            red = 0xFF;
                        }
                        const green = (((((colorCode / 3) | 0) % 3) * 0xFF) / 2) | 0;
                        const blue = (((((colorCode / 9) | 0) % 3) * 0xFF) / 2) | 0;
                        const redCode = ('00' + red.toString(16)).slice(-2);
                        const greenCode = ('00' + green.toString(16)).slice(-2);
                        const blueCode = ('00' + blue.toString(16)).slice(-2);
                        const fill = `#${redCode}${blueCode}${greenCode}`;
                        child.style.fill = fill;
                        colorCode = (colorCode * 1664525 + 1664525) & 0x7FFFFFFF;
                    }
                    this.#CharacterPrevious = this.#Character;
                    this.#ColorSeedPrevious = this.#ColorSeed;
                }
            }
        }
    }

    _ReceiveSetProperty(name, value) {
        switch (name) {
            case 'Room':
                this.#Room = value;
                this.#Element = null;
                break;
            case 'ElementId':
                this.#ElementId = value;
                this.#Element = null;
                break;
            case 'Character':
                this.#Character = value;
                break;
            case 'ColorSeed':
                this.#ColorSeed = value;
                break;
            default:
                super._ReceiveSetProperty(name, value);
                break;
        }
    }
}

SyncObject.RegisterType(SevenSegment, 'SevenSegment');
