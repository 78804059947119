import { Connection } from "./Connection.js";
import { WebsocketConnection } from "./WebsocketConnection.js";

export class LocalConnection extends Connection {
    _Other = null;
    #Queue = [];

    get Id() {
        return WebsocketConnection.LocalId;
    }

    constructor(createOther = true) {
        super();
        if (createOther) {
            const other = new LocalConnection(false);
            this._Other = other;
            other._Other = this;
        }
    }

    Send(message) {
        this.#Queue.push(message);
    }

    _Transmit(transmitOther = true) {
        for (const message of this.#Queue) {
            this._Other._OnMessage(message);
        }
        this.#Queue.length = 0;
        if (transmitOther) {
            this._Other._Transmit(false);
        }
    }
}
