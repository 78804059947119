import { SyncObject } from "../Engine/SyncObject.js";

export class Child extends SyncObject {
    #Parents = new Set();
    #Name = null;

    constructor() {
        super();
    }

    get Parents() {
        return Array.from(this.#Parents);
    }

    get Name() {
        return this.#Name;
    }

    set NameNext(value) {
        this._SendSetProperty('Name', value);
    }

    Update(time, dt) {
    }

    FindDescendent(name) {
        if (this.Name === name) {
            return this;
        }
        return null;
    }

    _AddParent(value) {
        this.#Parents.add(value);
    }

    _RemoveParent(value) {
        this.#Parents.delete(value);
    }

    _ReceiveSetProperty(name, value) {
        switch (name) {
            case 'Name':
                this.#Name = value;
                break;
            default:
                super._ReceiveSetProperty(name, value);
                break;
        }
    }
}