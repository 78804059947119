import { Server } from "../Engine/Server.js";
import { Game } from "./Game.js";
import { Player } from "./Player.js";
import { Room00 } from "./Room00.js";
import { Room01 } from "./Room01.js";
import { Room02 } from "./Room02.js";

const ConnectionState = {
    New: 0,
    Open: 1,
    Timeout: 2
};

export class SimpescServer extends Server {
    #Room00 = new Room00();
    #Room01 = new Room01();
    #Room02 = new Room02();
    #Rooms = null;
    #PlayerClicked = new WeakMap();
    #Game = new Game();
    #PlayerId = 0;
    #ConnectionData = new WeakMap();

    constructor(id) {
        super(id);
        this.#Room00.Server = this;
        this.#Room00.SetNextSource('/static/img/room00.svg');
        this.#Room00.OnExit.add(this.#Room00OnExit.bind(this));

        this.#Room01.Server = this;
        this.#Room01.SetNextSource('/static/img/room01.svg');
        this.#Room01.OnExit.add(this.#Room01OnExit.bind(this));

        this.#Room02.Server = this;
        this.#Room02.SetNextSource('/static/img/room02.svg');
        this.#Room02.OnExit.add(this.#Room02OnExit.bind(this));

        this.#Rooms = [
            this.#Room00,
            this.#Room01,
            this.#Room02,
        ];
    }

    Update(time, dt) {
        super.Update(time, dt);

        for (const connection of this.Connections) {
            if (!this.#ConnectionData.has(connection)) {
                this.#ConnectionData.set(
                    connection,
                    {
                        state: ConnectionState.New,
                        player: null,
                        room: null,
                    });
            }
            const connectionData = this.#ConnectionData.get(connection);
            switch (connectionData.state) {
                case ConnectionState.New:
                    {
                        const room = this.#Room00;
//                        const room = this.#Room01;
//                        const room = this.#Room02;
                        const player = new Player();
                        player.SetNextRoom(room);
                        player.GameNext = this.#Game;
                        player.PlayerIdNext = this.#PlayerId++;
                        this.#Game.AddPlayerNext(player);
                        room.AddChildNext(player);
                        connection.ClientDataNext = player;
                        connectionData.player = player;
                        connectionData.state = ConnectionState.Open;
                    }
                    break;
                case ConnectionState.Open:
                    {
                        // Update click.
                        const player = connection.ClientData;
                        const newClicked = player.Clicked ?? null;
                        const oldClicked = this.#PlayerClicked.get(player) ?? null;
                        if (newClicked != oldClicked) {
                            this.#PlayerClicked.set(player, newClicked);
                            if (newClicked && newClicked.OnClick) {
                                for (const handler of newClicked.OnClick) {
                                    try {
                                        handler(newClicked, player);
                                    }
                                    catch (err) {
                                        console.error(err);
                                    }
                                }
                            }
                        }
                        // Check for connection timeout.
                        if (connection.IsTimedOut) {
                            player.SetNextRoom(null);
                            for (const room of this.#Rooms) {
                                room.RemoveChildNext(player);
                            }
                            player.GameNext = null;
                            this.#Game.RemovePlayerNext(player);
                            connectionData.state = ConnectionState.Timeout;
                            connectionData.room = player.Room;
                        }
                    }
                    break;
                case ConnectionState.Timeout:
                    {
                        if (!connection.IsTimedOut) {
                            const room = connectionData.room ?? this.#Room00;
                            const player = connectionData.player;
                            player.SetNextRoom(room);
                            room.AddChildNext(player);
                            player.GameNext = this.#Game;
                            this.#Game.AddPlayerNext(player);
                            connectionData.room = null;
                            connectionData.state = ConnectionState.Open;
                        }
                    }
                    break;
                default:
                    console.error('Unknown connection state');
                    break;
            }
        }
        this.#Room00.Update(time, dt);
        this.#Room01.Update(time, dt);
        this.#Room02.Update(time, dt);
    }

    #Room00OnExit(target, player, room) {
        const oldRoom = player.Room;
        const newRoom = this.#Room01;
        oldRoom.RemoveChildNext(player);
        player.SetNextRoom(newRoom);
        newRoom.AddChildNext(player);
    }

    #Room01OnExit(target, player, room) {
        const oldRoom = player.Room;
        let newRoom = this.#Room00;
        if (target.Name === 'exit1') {
            newRoom = this.#Room02;
        }
        oldRoom.RemoveChildNext(player);
        player.SetNextRoom(newRoom);
        newRoom.AddChildNext(player);
    }

    #Room02OnExit(target, player, room) {
        const oldRoom = player.Room;
        const newRoom = this.#Room01;
        oldRoom.RemoveChildNext(player);
        player.SetNextRoom(newRoom);
        newRoom.AddChildNext(player);
    }
}
