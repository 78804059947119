import { Body } from "./Body.js";
import { SyncObject } from "../Engine/SyncObject.js";

export class Glasses extends Body {
    #Wearer = null;

    constructor() {
        super();
    }

    Update(time, dt) {
        super.Update(time, dt);

        if (this.Client) {
            const wearers = [...this.HeldBy].filter(holder => holder.HoldData?.wearing);
            wearers.sort((a, b) => {
                const ai = a.IsClientPlayer ? -1 : a.PlayerId;
                const bi = b.IsClientPlayer ? -1 : b.PlayerId;
                return ai - bi;
            });
            const wearer = wearers.length && wearers[0];
            const oldWearer = this.#Wearer;
            const newWearer = wearer;
            const getOverlay = (player) => {
                if (!player) {
                    return null;
                }
                const avatarOverlayId = `avatarover${player.PlayerId}`;
                const avatarOverlay = document.getElementById(avatarOverlayId);
                return avatarOverlay;
            };
            if (newWearer != oldWearer) {
                const oldOverlay = getOverlay(oldWearer);
                const newOverlay = getOverlay(newWearer);
                if (!(oldWearer && !oldOverlay) && !(newWearer && !newOverlay)) {
                    if (oldWearer) {
                        oldOverlay.innerHTML = '';
                    }
                    if (wearer) {
                        newOverlay.innerHTML = this.Svg.innerHTML;
                        const transform = newOverlay.transform.baseVal;
                        if (transform.length !== 1) {
                            const item = this.Svg.viewportElement.createSVGTransform();
                            item.setTranslate(0, 0);
                            transform.appendItem(item);
                            transform.consolidate();
                        }
                        const matrix = this.Svg.viewportElement.createSVGMatrix();
                        matrix.a = 0.5;
                        matrix.d = 0.5;
                        matrix.e = 72;
                        matrix.f = 32;
                        transform.getItem(0).setMatrix(matrix);
                    }
                    this.#Wearer = wearer;
                }
            }
            const svg = this.Svg;
            const lens = document.getElementById('glasseslens');
            if (svg && lens) {
                if (wearer) {
                    if (wearer.IsClientPlayer) {
                        const screenWidth = this.Client.ScreenWidth;
                        const screenHeight = this.Client.ScreenHeight;

                        const glassesWidth = 227;
                        const glassesHeight = 86.5;
                        const widthScale = screenWidth / glassesWidth;
                        const heightScale = screenHeight / glassesHeight;
                        const scale = Math.min(widthScale, heightScale) * 0.9;

                        const scaledWidth = glassesWidth * scale;
                        const scaledHeight = glassesHeight * scale;
                        const spareWidth = screenWidth - scaledWidth;
                        const spareHeight = screenHeight - scaledHeight;

                        const transform = svg.transform.baseVal;
                        const parentMatrixInverse = svg.parentElement.getScreenCTM().inverse();
                        const matrix = svg.viewportElement.createSVGMatrix();
                        matrix.a = scale;
                        matrix.d = scale;
                        matrix.e = spareWidth / 2;
                        matrix.f = spareHeight / 2;
                        const wearMatrix = parentMatrixInverse.multiply(matrix);
                        transform.getItem(0).setMatrix(wearMatrix);

                        lens.style.mixBlendMode = 'multiply';
                    }
                    else {
                        svg.style.display = 'none';
                    }
                }
                else {
                    svg.style.display = 'inherit';
                    lens.style.mixBlendMode = 'normal';
                }
            }
        }
    }

    _OnHold(event) {
        const player = this.Client.Player;
        if (player.HeldObject !== this) {
            super._OnHold(event);
        }
    }

    _OnDrop(event) {
        const player = this.Client?.Player;
        let avatar = null;
        if (player) {
            const game = player.Game;
            if (game) {
                avatar = game.GetAvatarUnderCursor(event);
            }
        }
        if (player.HeldObject === this) {
            if (player.HoldData?.wearing) {
                if (!event.viewChanged) { // Don't drop if the gesture was a pinch or pan.)
                    super._OnDrop(event);
                }
            }
            else {
                if (player === avatar) {
                    player.SetNextHeld(this, { wearing: true });
                }
                else {
                    super._OnDrop(event);
                }
            }
        }
    }
}

SyncObject.RegisterType(Glasses, 'Glasses');