export class float2x3 {
    #Elements = new Float32Array([
        1, 0, 0,
        0, 1, 0
    ]);

    Clone() {
        return new float2x3().Copy(this);
    }

    Copy(other) {
        for (let i = 0; i < 6; ++i) {
            this.#Elements[i] = other.#Elements[i];
        }
        return this;
    }


    Get(row, column) {
        return this.#Elements[row * 3 + column];
    }

    Set(row, column, value) {
        this.#Elements[row * 3 + column] = value;
        return this;
    }

    MakeTranslation(pos) {
        this.#Elements[0] = 1; this.#Elements[1] = 0; this.#Elements[2] = pos.x;
        this.#Elements[3] = 0; this.#Elements[4] = 1; this.#Elements[5] = pos.y;
        return this;
    }

    MakeRotation(angle) {
        const sin = Math.sin(angle);
        const cos = Math.cos(angle);
        this.#Elements[0] = cos; this.#Elements[1] = -sin; this.#Elements[2] = 0;
        this.#Elements[3] = sin; this.#Elements[4] = cos; this.#Elements[5] = 0;
        return this;
    }

    Multiply(other) {
        const t00 = this.#Elements[0];
        const t01 = this.#Elements[1];
        const t02 = this.#Elements[2];
        const t10 = this.#Elements[3];
        const t11 = this.#Elements[4];
        const t12 = this.#Elements[5];
        const o00 = other.#Elements[0];
        const o01 = other.#Elements[1];
        const o02 = other.#Elements[2];
        const o10 = other.#Elements[3];
        const o11 = other.#Elements[4];
        const o12 = other.#Elements[5];
        this.#Elements[0] = t00 * o00 + t01 * o10;
        this.#Elements[1] = t00 * o01 + t01 * o11;
        this.#Elements[2] = t00 * o02 + t01 * o12 + t02;
        this.#Elements[3] = t10 * o00 + t11 * o10;
        this.#Elements[4] = t10 * o01 + t11 * o11;
        this.#Elements[5] = t10 * o02 + t11 * o12 + t12;
        return this;
    }

    static FromDOMMatrix(matrix) {
        const result = new float2x3();
        result.Set(0, 0, matrix.a);
        result.Set(0, 1, matrix.c);
        result.Set(0, 2, matrix.e);
        result.Set(1, 0, matrix.b);
        result.Set(1, 1, matrix.d);
        result.Set(1, 2, matrix.f);
        return result;
    }
}
