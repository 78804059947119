
export class SyncObject {
    #appliedMessages = new Set();
    #pendingMessages = new Set();
    #onSendMessage = new Set();
    #onApplyMessage = new Set();
    #Client = null;
    static #names = new Map();
    static #types = new Map();

    constructor() {
    }

    get OnSendMessage() {
        return this.#onSendMessage;
    }

    get OnApplyMessage() {
        return this.#onApplyMessage;
    }

    get AppliedMessages() {
        return Array.from(this.#appliedMessages);
    }

    get Client() {
        return this.#Client;
    }

    set Client(value) {
        const oldValue = this.#Client;
        this.#Client = value;
        this._OnClientChanged(value, oldValue)
    }

    HasMessage(message) {
        return this.#appliedMessages.has(message) || this.#pendingMessages.has(message);
    }

    ApplyPendingMessages() {
        for (const message of this.#pendingMessages) {
            this._ApplyPendingMessage(message);
        }
        this.#pendingMessages.clear();
    }

    ClearPendingAndApplied() {
        this.#pendingMessages.clear();
        this.#appliedMessages.clear();
    }

    _OnClientChanged(value, oldValue) {
    }

    _ApplyPendingMessage(message) {
        try {
            this._ReceiveMessage(message);
        }
        catch (err) {
            console.error(err);
        }
        for (const listener of this.#onApplyMessage) {
            try {
                listener.call(undefined, this, message);
            }
            catch (err) {
                console.error(err);
            }
        }
        this.#appliedMessages.add(message);
        this._UniquifyMessages(this.#appliedMessages);
    }

    _SendMessage(message) {
        this.#pendingMessages.add(message);
        for (const listener of this.OnSendMessage) {
            try {
                listener.call(undefined, this);
            }
            catch (err) {
                console.error(err);
            }
        }
    }

    _SendSetProperty(name, value) {
        this._SendMessage({
            type: "property",
            name,
            value
        });
    }

    _ReceiveMessage(message) {
        switch (message.type) {
            case "property":
                this._ReceiveSetProperty(message.name, message.value);
                break;
            default:
                console.error(`unknown message type: ${message.type}`, message);
                break;
        }
    }

    _ReceiveSetProperty(name, value) {
        console.error(`Unknown set property: ${name}`, value);
    }

    _UniquifyMessages(messageSet) {
        const messageMap = new Map(Array.from(messageSet).map(message => [message, undefined]));
        this._ClassifyMessages(messageMap);
        for (const [message, classification] of messageMap) {
            if (classification === false) {
                messageSet.delete(message);
            }
            else if (classification === undefined) {
                console.warn('unclassified message', message);
            }
        }
    }

    _ClassifyMessages(messageMap) {
        const lastOfPropertyName = new Map();
        for (const message of messageMap.keys()) {
            switch (message.type) {
                case 'property':
                    const propertyName = message.name;
                    lastOfPropertyName.set(propertyName, message);
                    break;
                default:
                    break;
            }
        }
        const keepProperties = new Set(lastOfPropertyName.values());
        for (const message of messageMap.keys()) {
            switch (message.type) {
                case 'property':
                    messageMap.set(message, keepProperties.has(message));
                    break;
                default:
                    break;
            }
        }
    }

    static RegisterType(type, name) {
        this.#types.set(name, type);
        this.#names.set(type, name);
    }

    static CreateType(name) {
        const type = this.#types.get(name);
        return new type();
    }

    static GetTypeName(object) {
        const type = object.constructor;
        return this.#names.get(type);
    }
}
