import { float2 } from "../Engine/float2.js";
import { SyncObject } from "../Engine/SyncObject.js";
import { Body } from "./Body.js";
import { Parent } from "./Parent.js";
import { Player } from "./Player.js";
import { Sound } from "./Sound.js";

export class Room extends Parent {
    Svg = null;
    #Server = null;
    #Source = null;
    #OnExit = new Set();
    #Layers = new Map();
    #World = null;
    #WorldBody = null;
    #OnBeginContactBind = null;
    #OnEndContactBind = null;
    #Contacts = new WeakMap();
    #ContactChanges = new Map();

    constructor() {
        super();
        this.#OnBeginContactBind = this.#OnBeginContact.bind(this);
        this.#OnEndContactBind = this.#OnEndContact.bind(this);
    }

    get Server() {
        return this.#Server;
    }

    set Server(value) {
        this.#Server = value;
        this.#LoadRoomAsync();
    }

    get Source() {
        return this.#Source;
    }

    SetNextSource(value) {
        this._SendSetProperty('Source', value);
    }

    get OnExit() {
        return this.#OnExit;
    }

    get World() {
        if (!this.#World) {
            this.#World = new planck.World();
            const gravity = planck.Vec2(0, 9.81);
            this.#World.setGravity(gravity);
            this.#World.on('begin-contact', this.#OnBeginContactBind);
            this.#World.on('end-contact', this.#OnEndContactBind);

            const width = 1920;
            const height = 1080;
            const body = this.#World.createBody({
                type: 'static',
                position: planck.Vec2((width / 2) / Room.pixelsPerMeter, (height * 2) / Room.pixelsPerMeter)
            });
            body.createFixture(planck.Box(width * 1.5 / Room.pixelsPerMeter, height / Room.pixelsPerMeter), { density: 1 });
        }
        return this.#World;
    }

    // Get a body which can be used to create constraints with the world.
    get WorldBody() {
        if (!this.#WorldBody) {
            const world = this.World;
            const worldBody = world.createBody(
                {
                    type: 'static',
                });
            const staticBox = planck.Box(1, 1);
            worldBody.createFixture(
                staticBox,
                {
                    density: 1,
                    filterCategoryBits: 0,
                    filterMaskBits: 0,
                });
            this.#WorldBody = worldBody;
        }
        return this.#WorldBody;
    }

    Update(time, dt) {
        super.Update(time, dt);

        this.#UpdatePhysics(time, dt);

        if (this.Client) {
            // Update layer zoom.
            for (const [name, { zoom }] of this.#Layers) {
                this.#ScaleLayer(name, zoom);
            }
        }
    }

    #UpdatePhysics(time, dt) {
        const minDt = 1000 / 60;
        const maxDt = 1000 / 15;
        let dtRemaining = dt;
        this.#ContactChanges.clear();
        while (dtRemaining > 0) {
            let dtStep = Math.min(dtRemaining, maxDt);
            dtRemaining -= dtStep;
            if (dtRemaining < minDt) {
                dtStep += dtRemaining;
                dtRemaining = 0;
            }
            const dtStepSeconds = dtStep / 1000;
            this.World.step(dtStepSeconds);
        }
        for (const [bodyA, mapA] of this.#ContactChanges) {
            for (const [bodyB, contact] of mapA) {
                if (contact) {
                    bodyA.AddContactNext(bodyB);
                }
                else {
                    bodyA.RemoveContactNext(bodyB);
                }
            }
        }
    }

    GetLayer(element) {
        while (element) {
            const id = element.id;
            const layerData = this.#Layers.get(id);
            if (layerData) {
                const { zoom } = layerData;
                return zoom;
            }
            element = element.parentElement;
        }
        return 1;
    }

    IsLayer(element) {
        const id = element.id;
        return this.#Layers.has(id);
    }

    _AddSound(name, url) {
        const sound = new Sound();
        sound.NameNext = name;
        sound.SourceNext = url;
        this.AddChildNext(sound);
    }

    _PlaySound(name, volume) {
        const sound = this.FindDescendent(name);
        if (sound) {
            return sound.Play(volume);
        }
    }

    _OnClientChanged(value, oldValue) {
        super._OnClientChanged(value, oldValue);
        this.#LoadRoomAsync();
    }

    _AddLayer(name, zoom) {
        this.#Layers.set(name, { zoom });
    }

    _ReceiveSetProperty(name, value) {
        switch (name) {
            case 'Source':
                this.#Source = value;
                this.#LoadRoomAsync();
                break;
            default:
                super._ReceiveSetProperty(name, value);
                break;
        }
    }

    _OnExit(group, player, room) {
        for (const handler of this.#OnExit) {
            try {
                handler(group, player, room);
            }
            catch (err) {
                console.error(err);
            }
        }
    }

    _LoadedRoom() {
        document.getElementById('gameLayer').style.display = 'flex';
    }

    _Body(element, fixtureElements, dynamic = true, bodyObject = null, bodyType = undefined, bodyFilter = undefined, options = undefined) {
        const groupId = element.id;
        const elementToWorld = this._GetElementTransform(element);
        const elementTransform = this.DecomposeMatrix(elementToWorld);

        // Get the collision matrix PBC
        const fixtures
            = fixtureElements
            .map(collisionElement => {
                const collisionToWorld = this._GetElementTransform(collisionElement);
                const fixture = {
                    typeMask: bodyType ?? 1,
                    filterMask: bodyFilter ?? 1,
                    isSensor: options?.isSensor,
                };
                switch (collisionElement.tagName) {
                    case 'rect':
                        {
                            const width = Number.parseFloat(collisionElement.getAttribute('width'));
                            const height = Number.parseFloat(collisionElement.getAttribute('height'));
                            const x = Number.parseFloat(collisionElement.getAttribute('x'));
                            const y = Number.parseFloat(collisionElement.getAttribute('y'));
                            const collisionMatrix = new DOMMatrix([width, 0, 0, height, x + width / 2, y + height / 2]);
                            collisionToWorld.multiplySelf(collisionMatrix);
                            const collisionTransform = this.DecomposeMatrix(collisionToWorld);
                            // Decompose collision matrix into size position and rotation.
                            const boxWidth = collisionTransform.scale.x;
                            const boxHeight = collisionTransform.scale.y;
                            const boxTranslate = collisionTransform.translate;
                            fixture.type = 'box';
                            fixture.width = boxWidth;
                            fixture.height = boxHeight;
                            fixture.position = boxTranslate.ToSimple();
                            fixture.rotation = collisionTransform.rotate;
                        }
                        break;
                    case 'circle':
                        {
                            const cx = Number.parseFloat(collisionElement.getAttribute('cx'));
                            const cy = Number.parseFloat(collisionElement.getAttribute('cy'));
                            const r = Number.parseFloat(collisionElement.getAttribute('r'));
                            const collisionMatrix = new DOMMatrix([r, 0, 0, r, cx, cy]);
                            collisionToWorld.multiplySelf(collisionMatrix);
                            const collisionTransform = this.DecomposeMatrix(collisionToWorld);
                            // Decompose collision matrix into size position and rotation.
                            fixture.type = 'circle';
                            fixture.radius = collisionTransform.scale.x;
                            fixture.position = collisionTransform.translate.ToSimple();
                        }
                        break;
                    case 'path':
                        const d = collisionElement.getAttribute('d');
                        const tokens = d.split(' ').map(d2 => d2.split(',')).flat();
                        const points = [];
                        let tokenIndex = 0;
                        let mode = null;
                        while (tokenIndex < tokens.length) {
                            const token = tokens[tokenIndex];
                            switch (token) {
                                case 'M':
                                case 'm':
                                case 'H':
                                case 'V':
                                case 'h':
                                case 'v':
                                case 'l':
                                    mode = token;
                                    break;
                                case 'z':
//                                    points.push(points[0]);
                                    break;
                                default:
                                    const back = points.length > 0 ? points[points.length - 1] : [0, 0];
                                    switch (mode) {
                                        case 'M':
                                        case 'm':
                                            {
                                                const x = Number.parseFloat(tokens[tokenIndex++]);
                                                const y = Number.parseFloat(tokens[tokenIndex]);
                                                points.push([x, y]);
                                                mode = 'l';
                                            }
                                            break;
                                        case 'l':
                                            {
                                                const dx = Number.parseFloat(tokens[tokenIndex++]);
                                                const dy = Number.parseFloat(tokens[tokenIndex]);
                                                points.push([back[0] + dx, back[1] + dy]);
                                            }
                                            break;
                                        case 'H':
                                            {
                                                const x = Number.parseFloat(tokens[tokenIndex]);
                                                points.push([x, back[1]]);
                                            }
                                            break;
                                        case 'h':
                                            {
                                                const dx = Number.parseFloat(tokens[tokenIndex]);
                                                points.push([back[0] + dx, back[1]]);
                                            }
                                            break;
                                        case 'V':
                                            {
                                                const y = Number.parseFloat(tokens[tokenIndex]);
                                                points.push([back[0], y]);
                                            }
                                            break;
                                        case 'v':
                                            {
                                                const dy = Number.parseFloat(tokens[tokenIndex]);
                                                points.push([back[0], back[1] + dy]);
                                            }
                                            break;
                                        default:
                                            console.error('Unknown path command', mode);
                                            break;
                                    }
                                    break;
                            }
                            ++tokenIndex;
                        }
                        fixture.type = 'path';
                        fixture.points = points;
                        break;
                    default:
                        console.error('Unknown fixture element type', collisionElement.tagName);
                        return null;
                }
                return fixture;
            })
            .filter(i => i);

        const body = bodyObject || new Body();
        body.NameNext = groupId;
        body.RoomNext = this;
        body.ElementIdNext = groupId;
        body.InitialiseNext(elementTransform.translate, elementTransform.rotate, elementTransform.scale, fixtures, dynamic);
        this.AddChildNext(body);

        return body;
    }

    async #LoadRoomAsync() {
        try {
            const source = this.Source;
            const client = this.Client;
            const server = this.Server;
            if (!source || (!client && !server)) {
                return;
            }
            const response = await fetch(source);
            const text = await response.text();
            if (source === this.Source) {
                const parser = new DOMParser();
                this.Svg = parser.parseFromString(text, 'text/html').body.children[0];
            }
            this._LoadedRoom();
        }
        catch (err) {
            console.error(err);
        }
    }

    DecomposeMatrix(matrix) {
        const xAxis = new float2(matrix.a, matrix.b);
        const yAxis = new float2(matrix.c, matrix.d);
        const offset = new float2(matrix.e, matrix.f);
        const xScale = xAxis.Length();
        const yScale = yAxis.Length();
        const skew = xAxis.Dot(yAxis) / (xScale * yScale);
        if (skew > 1e-6) {
            console.error(`collision rectangle is skewed`);
        }
        const translate = offset;
        const rotate = Math.atan2(xAxis.y, xAxis.x);
        const scale = new float2(xScale, yScale);
        return {
            translate,
            rotate,
            scale
        };
    }

    _GetElementTransform(element) {
        const result = new DOMMatrix([1, 0, 0, 1, 0, 0]);
        const transform = element?.transform;
        if (transform) {
            const items = transform.baseVal;
            const count = items.length;
            for (let i = 0; i < count; ++i) {
                const component = items.getItem(i);
                result.multiplySelf(component.matrix);
            }
            const parentMatrix = this._GetElementTransform(element.parentElement);
            result.preMultiplySelf(parentMatrix);
        }
        return result;
    }

    GetLayerZoom(layer) {
        // To get a (z)oom factor at (c)amera zoom, set (l)ayer zoom to.
        // l = z * c / (1 + z * c - z)
        // 8 * 16 / (1 + 8 * 16 - 8)
        const l = layer;
        const c = this.Client.Zoom;
        let zoom = l / (l + c - l * c);
        if (zoom < 0 || isNaN(zoom) || !isFinite(zoom)) {
            // Hide the layer.
            zoom = 0;
        }
        return zoom;
    }

    #ScaleLayer(id, amount) {
        if (!this.Svg) {
            return;
        }
        const layer1 = this.Svg.getElementById(id);
        if (!layer1) {
            return;
        }
        const transform = layer1.transform.baseVal;
        if (transform.length !== 1) {
            if (transform.length === 0) {
                const item = this.Svg.createSVGTransform();
                item.setTranslate(0, 0);
                transform.appendItem(item);
            }
            transform.consolidate();
        }
        const zoom = this.GetLayerZoom(amount);
        const offset = this.Client.Offset;
        const pivot = layer1.viewportElement.createSVGMatrix();
        pivot.a = 1;
        pivot.b = 0;
        pivot.c = 0;
        pivot.d = 1;
        pivot.e = -offset.x;
        pivot.f = -offset.y;
        const pivotInverse = layer1.viewportElement.createSVGMatrix();
        pivotInverse.a = 1;
        pivotInverse.b = 0;
        pivotInverse.c = 0;
        pivotInverse.d = 1;
        pivotInverse.e = offset.x;
        pivotInverse.f = offset.y;
        const zoomMatrix = layer1.viewportElement.createSVGMatrix();
        zoomMatrix.a = zoom;
        zoomMatrix.b = 0;
        zoomMatrix.c = 0;
        zoomMatrix.d = zoom;
        zoomMatrix.e = 0;
        zoomMatrix.f = 0;
        transform.getItem(0).setMatrix(pivotInverse.multiply(zoomMatrix).multiply(pivot));
    }

    #OnBeginContact(contact) {
        const bodyA = contact.getFixtureA().getBody();
        const bodyB = contact.getFixtureB().getBody();
        const worldManifold = contact.getWorldManifold();
        let speed = 0;
        if (worldManifold?.points) {
            for (const worldPoint of worldManifold.points) {
                const velocityA = bodyA.getLinearVelocityFromWorldPoint(worldPoint);
                const velocityB = bodyB.getLinearVelocityFromWorldPoint(worldPoint);
                const relativeSpeed = planck.Vec2.distance(velocityA, velocityB);
                speed = Math.max(speed, relativeSpeed);
            }
        }
        const gameBodyA = Body.GetBodyFromPhysics(bodyA);
        const gameBodyB = Body.GetBodyFromPhysics(bodyB);
        if (gameBodyA) {
            gameBodyA._Contact(speed);
        }
        if (gameBodyB) {
            gameBodyB._Contact(speed);
        }

        if (gameBodyA && gameBodyB) {
            if (!this.#Contacts.has(gameBodyA)) {
                this.#Contacts.set(gameBodyA, new Map());
            }
            if (!this.#Contacts.has(gameBodyB)) {
                this.#Contacts.set(gameBodyB, new Map());
            }
            const mapA = this.#Contacts.get(gameBodyA);
            const mapB = this.#Contacts.get(gameBodyB);
            if (!mapA.has(gameBodyB)) {
                mapA.set(gameBodyB, 0);
            }
            if (!mapB.has(gameBodyA)) {
                mapB.set(gameBodyA, 0);
            }
            const contactCount = mapA.get(gameBodyB);
            mapA.set(gameBodyB, contactCount + 1);
            mapB.set(gameBodyA, contactCount + 1);
            if (contactCount === 0) {
                if (!this.#ContactChanges.has(gameBodyA)) {
                    this.#ContactChanges.set(gameBodyA, new Map());
                }
                if (!this.#ContactChanges.has(gameBodyB)) {
                    this.#ContactChanges.set(gameBodyB, new Map());
                }
                const bodyAMap = this.#ContactChanges.get(gameBodyA);
                const bodyBMap = this.#ContactChanges.get(gameBodyB);
                bodyAMap.set(gameBodyB, true);
                bodyBMap.set(gameBodyA, true);
            }
        }
    }

    #OnEndContact(contact) {
        const bodyA = contact.getFixtureA().getBody();
        const bodyB = contact.getFixtureB().getBody();
        const gameBodyA = Body.GetBodyFromPhysics(bodyA);
        const gameBodyB = Body.GetBodyFromPhysics(bodyB);
        if (gameBodyA && gameBodyB) {
            if (!this.#Contacts.has(gameBodyA)) {
                this.#Contacts.set(gameBodyA, new Map());
            }
            if (!this.#Contacts.has(gameBodyB)) {
                this.#Contacts.set(gameBodyB, new Map());
            }
            const mapA = this.#Contacts.get(gameBodyA);
            const mapB = this.#Contacts.get(gameBodyB);
            if (!mapA.has(gameBodyB)) {
                console.error('Too many end contact')
            }
            if (!mapB.has(gameBodyA)) {
                console.error('Too many end contact')
            }
            const contactCount = mapA.get(gameBodyB);
            mapA.set(gameBodyB, contactCount - 1);
            mapB.set(gameBodyA, contactCount - 1);
            if (contactCount === 1) {
                if (!this.#ContactChanges.has(gameBodyA)) {
                    this.#ContactChanges.set(gameBodyA, new Map());
                }
                if (!this.#ContactChanges.has(gameBodyB)) {
                    this.#ContactChanges.set(gameBodyB, new Map());
                }
                const bodyAMap = this.#ContactChanges.get(gameBodyA);
                const bodyBMap = this.#ContactChanges.get(gameBodyB);
                bodyAMap.set(gameBodyB, false);
                bodyBMap.set(gameBodyA, false);
            }
        }
    }
}

Room.pixelsPerMeter = 256;

SyncObject.RegisterType(Room, 'Room');
