import { Client } from "../Engine/Client.js";
import { float2 } from "../Engine/float2.js";
import { Player } from './Player.js';
import { Element } from "./Element.js";

const minZoom = 1;
const maxZoom = 1024;

export class SimpescClient extends Client {
    Zoom = 1;
    Offset = new float2(1920 / 2, 1080 / 2);
    #UnderCursor = new float2();
    #Svg = null;
    #Cam = null;
    #CtrlKey = false;
    #ScreenWidth = 1920;
    #ScreenHeight = 1080;
    #PlayerData = new Map();
    #OldRoom = null;
    #Captured = null;
    #DownPointers = new Map();

    _Reset() {
        super._Reset();
        this.Zoom = 1;
        this.Offset = new float2(1920 / 2, 1080 / 2);
        this.#UnderCursor = new float2();
        this.#CtrlKey = false;
        this.#ScreenWidth = 1920;
        this.#ScreenHeight = 1080;
        this.#PlayerData = new Map();
    }

    get Player() {
        return this.ClientData;
    }

    constructor(connection) {
        super(connection);
        const client = this;
        const svg = document.getElementById('gamesvg');
        const cam = document.getElementById('gamecam');
        this.#Svg = svg;
        this.#Cam = cam;

        const updatePlayerPosition = e => {
            var p = svg.createSVGPoint();
            p.x = e.clientX;
            p.y = e.clientY;
            const svgPos = p.matrixTransform(cam.getScreenCTM().inverse());
            let svgX = svgPos.x;
            let svgY = svgPos.y;
            const player = client.ClientData;
            if (player) {
                const room = player.Room;
                if (room) {
                    const layer = room.GetLayer(e.target);
                    const zoom = room.GetLayerZoom(layer);
                    player.LayerNext = layer;
                    svgX -= this.Offset.x;
                    svgY -= this.Offset.y;
                    svgX /= zoom;
                    svgY /= zoom;
                    svgX += this.Offset.x;
                    svgY += this.Offset.y;
                }
                player.SetNextPosition({ x: svgX, y: svgY });
            }
        };
        const findGameElement = (e) => {
            if (client.#Captured) {
                return client.#Captured;
            }
            const midX = e.clientX;
            const midY = e.clientY;
            const width = e.width || 0;
            const height = e.height || 0;
            const samples = 5;
            let bestElement = null;
            let bestDistance = 1e6;
            for (let i = 0; i < samples; ++i) {
                const x = midX + (((i + 0.5) / samples) - 0.5) * width;
                const dx = x - midX;
                for (let j = 0; j < samples; ++j) {
                    const y = midY + (((j + 0.5) / samples) - 0.5) * height;
                    const dy = y - midY;
                    const d = Math.sqrt(dx * dx + dy * dy);
                    let domElement = document.elementFromPoint(x, y);
                    let gameElement = null;
                    while (domElement) {
                        gameElement = Element.FindElement(domElement);
                        if (gameElement) {
                            break;
                        }
                        domElement = domElement.parentElement;
                    }
                    if (gameElement) {
                        let behindCurrent = false;
                        let overCurrent = false;
                        if (bestElement) {
                            let current = bestElement.Svg;
                            while (current) {
                                if (current === gameElement.svg) {
                                    behindCurrent = true;
                                    break;
                                }
                                current = current.parentElement;
                            }
                            current = gameElement.svg;
                            while (current) {
                                if (current === bestElement.svg) {
                                    overCurrent = true;
                                    break;
                                }
                                current = current.parentElement;
                            }
                        }
                        const closer = d < bestDistance;
                        const isBetter = !behindCurrent && (overCurrent || closer);
                        if (isBetter) {
                            bestElement = gameElement;
                            bestDistance = d;
                        }
                    }
                }
            }
            return bestElement;
        };
        const eventWrapper = {
            Target: null,
            Capture: () => {
                client.#Captured = eventWrapper.Target;
            },
            Release: () => {
                client.#Captured = null;
            },
            clientX: -1,
            clientY: -1,
            viewChanged: false,
        };
        const pointerdown = this.pointerdown = e => {
            const pointerId = e.pointerId ?? 0;
            eventWrapper.clientX = e.clientX;
            eventWrapper.clientY = e.clientY;
            var p = svg.createSVGPoint();
            p.x = e.clientX;
            p.y = e.clientY;
            const svgPos = p.matrixTransform(cam.getScreenCTM().inverse());
            if (!this.#DownPointers.has(pointerId)) {
                this.#DownPointers.set(
                    pointerId,
                    {
                        buttons: 0,
                        startX: svgPos.x,
                        startY: svgPos.y,
                        currentX: e.clientX,
                        currentY: e.clientY,
                    });
            }
            const pointerData = this.#DownPointers.get(pointerId);
            pointerData.buttons = e.buttons;
            if (e.button === 2) {
                e.preventDefault();
                var p = svg.createSVGPoint();
                p.x = e.clientX;
                p.y = e.clientY;
                const svgPos = p.matrixTransform(cam.getScreenCTM().inverse());
                this.#UnderCursor.x = svgPos.x;
                this.#UnderCursor.y = svgPos.y;
            }
            else {
                if (this.#DownPointers.size === 1) {
                    eventWrapper.Release();
                    updatePlayerPosition(e);
                    const gameElement = findGameElement(e);
                    if (gameElement) {
                        eventWrapper.Target = gameElement;
                        gameElement._OnPointerDown(eventWrapper);
                    }
                }
                else if (this.#DownPointers.size > 1) {
                    for (const value of this.#DownPointers.values()) {
                        var p = svg.createSVGPoint();
                        p.x = value.currentX;
                        p.y = value.currentY;
                        const svgPos = p.matrixTransform(cam.getScreenCTM().inverse());
                        value.startX = svgPos.x;
                        value.startY = svgPos.y;
                    }
                }
            }
            e.target.setPointerCapture(pointerId);
        };
        const pointermove = this.pointermove = e => {
            const pointerId = e.pointerId ?? 0;
            eventWrapper.clientX = e.clientX;
            eventWrapper.clientY = e.clientY;
            if (e.buttons) {
                const pointerData = this.#DownPointers.get(pointerId);
                if (pointerData && e.buttons !== pointerData.buttons) {
                    pointerData.buttons = e.buttons;
                    eventWrapper.clientX = e.clientX;
                    eventWrapper.clientY = e.clientY;
                    var p = svg.createSVGPoint();
                    p.x = e.clientX;
                    p.y = e.clientY;
                    const svgPos = p.matrixTransform(cam.getScreenCTM().inverse());
                    pointerData.startX = svgPos.x;
                    pointerData.startY = svgPos.y;
                    this.#UnderCursor.x = svgPos.x;
                    this.#UnderCursor.y = svgPos.y;
                }
            }
            if (this.#DownPointers.size === 1 && (this.#DownPointers.values().next().value.buttons & 0x2) !== 0) {
                var p = svg.createSVGPoint();
                p.x = e.clientX;
                p.y = e.clientY;
                const svgPos = p.matrixTransform(cam.getScreenCTM().inverse());
                const dx = this.#UnderCursor.x - svgPos.x;
                const dy = this.#UnderCursor.y - svgPos.y;
                this.Offset.x += dx;
                this.Offset.y += dy;
                this.#UpdateCamera();
                eventWrapper.viewChanged = true;
            }
            else {
                if (this.#DownPointers.size < 2) {
                    updatePlayerPosition(e);
                    const gameElement = findGameElement(e);
                    if (gameElement) {
                        eventWrapper.Target = gameElement;
                        gameElement._OnPointerMove(eventWrapper);
                    }
                }
                else {
                    eventWrapper.viewChanged = true;
                }
                if (this.#DownPointers.has(pointerId)) {
                    const pointerData = this.#DownPointers.get(pointerId);
                    pointerData.currentX = e.clientX;
                    pointerData.currentY = e.clientY;
                }
            }
        };
        const pointerup = this.pointerup = e => {
            const pointerId = e.pointerId ?? 0;
            eventWrapper.clientX = e.clientX;
            eventWrapper.clientY = e.clientY;
            if (e.button === 2) {
                e.preventDefault();
                this.#CtrlKey = e.ctrlKey;
            }
            if (!e.buttons) {
                e.target.releasePointerCapture(pointerId);
                client.#DownPointers.delete(pointerId);
                if (this.#DownPointers.size === 0) {
                    updatePlayerPosition(e);
                    const gameElement = findGameElement(e);
                    if (gameElement) {
                        eventWrapper.Target = gameElement;
                        gameElement._OnPointerUp(eventWrapper);
                    }
                    eventWrapper.Release();
                    eventWrapper.viewChanged = false;
                }
            }
        };
        const click = this.click = e => {
            updatePlayerPosition(e);
            const gameElement = findGameElement(e);
            if (gameElement) {
                eventWrapper.Target = gameElement;
                gameElement._OnClick(eventWrapper);
            }
        };
        const wheel = this.wheel = e => {
            const delta = e.deltaY;
            const step = delta / (Math.abs(delta) || 1);
            let logZoom = Math.log2(this.Zoom);
            const steps = 8;
            let logZoomSteps = logZoom * steps;
            logZoomSteps -= step;
            logZoomSteps = Math.round(logZoomSteps);
            logZoom = logZoomSteps / steps;
            const oldZoom = this.Zoom;
            const newZoom = Math.min(Math.max(Math.pow(2, logZoom), minZoom), maxZoom);

            var p = svg.createSVGPoint();
            p.x = e.clientX;
            p.y = e.clientY;
            const svgPos = p.matrixTransform(cam.getScreenCTM().inverse());
            const dx = svgPos.x - this.Offset.x;
            const dy = svgPos.y - this.Offset.y;
            const zoomRatio = oldZoom / newZoom;

            this.Zoom = newZoom;
            this.Offset.x = svgPos.x - dx * zoomRatio;
            this.Offset.y = svgPos.y - dy * zoomRatio;
        };
        const contextmenu = this.contextmenu = e => {
            if (!this.#CtrlKey) {
                e.preventDefault();
            }
        };
        svg.addEventListener('pointerdown', pointerdown);
        svg.addEventListener('pointermove', pointermove);
        svg.addEventListener('pointerup', pointerup);
        svg.addEventListener('pointercancel', pointerup);
        svg.addEventListener('click', click);
        svg.addEventListener('wheel', wheel);
        svg.addEventListener('contextmenu', contextmenu);

        this.OnNewObject.add(this.#OnNewObject.bind(this));
    }

    get ScreenWidth() {
        return this.#ScreenWidth;
    }

    get ScreenHeight() {
        return this.#ScreenHeight;
    }

    SetCursor(svgString) {
        let style = document.getElementById('cursorStyle');
        if (!style) {
            style = document.createElement('style');
            style.id = 'cursorStyle';
            document.head.appendChild(style);
        }
        const pointerUrl = 'data:image/svg+xml;base64,' + btoa(svgString);
        style.innerHTML =
            'body {' +
            'cursor: ' + 'url(' + pointerUrl + '),default' + ';'
        '}';
    }

    Update(time, dt) {
        super.Update(time, dt);
        this.#UpdateCamera();
        const room = this.Player?.Room;
        if (room) {
            room.Update(time, dt);
            const svg = room.Svg;
            if (svg) {
                const roomElement = document.getElementById('room');
                if (roomElement.children.length > 0 && roomElement.children[0] !== svg) {
                    roomElement.removeChild(roomElement.children[0]);
                }
                if (roomElement.children.length === 0) {
                    roomElement.appendChild(svg);
                }
            }

            // Update cursors.
            for (const playerData of this.#PlayerData.values()) {
                playerData.found = false;
            }
            for (const child of room.Children) {
                if (child instanceof Player) {
                    if (!this.#PlayerData.has(child)) {
                        this.#PlayerData.set(
                            child,
                            {
                                found: false,
                                cursor: null,
                                playerId: -1,
                            });
                    }
                    this.#PlayerData.get(child).found = true;
                }
            }
            const cursorsGroup = document.getElementById('cursors');
            const activeCursors = new Set();
            for (const [player, playerData] of this.#PlayerData) {
                if (!playerData.found) {
                    this.#PlayerData.delete(player);
                }
                else {
                    if (!playerData.cursor || playerData.playerId !== player.PlayerId) {
                        if (playerData.cursor) {
                            if (playerData.cursor !== true) {
                                cursorsGroup.removeChild(playerData.cursor);
                            }
                            playerData.cursor = null;
                        }

                        let h = player?.Hue ?? 0;
                        const alpha = player.IsClientPlayer ? 1 : 0.4;
                        const svgString = Player.CursorSvg.replace('fill="#fff"', `fill="hsla(${h * 360},50%,50%,${alpha})"`);
                        if (player.IsClientPlayer) {
                            this.SetCursor(svgString);
                            playerData.cursor = true;
                        }
                        else {
                            const cursor = document.createElementNS("http://www.w3.org/2000/svg", 'g')
                            cursor.setAttributeNS(null, 'transform', `translate(0 0) scale(1 1)`);
                            cursor.innerHTML = svgString;
                            cursorsGroup.appendChild(cursor);
                            playerData.cursor = cursor;
                        }
                        playerData.playerId = player.PlayerId;
                    }

                    if (playerData.cursor !== true) {
                        const cam = document.getElementById('gamecam');
                        const camTransform = cam.getScreenCTM();
                        const scale = camTransform.a;
                        const iconScale = 1 / scale;

                        const cameraToPlayer = player.Position.Subtract(this.Offset);
                        const layerZoom = Math.max(room.GetLayerZoom(player.Layer) - 1, 0);
                        cameraToPlayer.MultiplyScalar(layerZoom);

                        const cursor = playerData.cursor;
                        const transform = cursor.transform.baseVal;
                        const position = player.PositionSmoothed.Add(cameraToPlayer);
                        transform.getItem(0).setTranslate(position.x, position.y);
                        transform.getItem(1).setScale(iconScale, iconScale);

                        activeCursors.add(cursor);
                    }
                }
            }
            for (const cursor of [...cursorsGroup.children]) {
                if (!activeCursors.has(cursor)) {
                    cursorsGroup.removeChild(cursor);
                }
            }
        }
    }

    Stop() {
        super.Stop();
        const gamesvg = document.getElementById('gamesvg');
        gamesvg.removeEventListener('pointerdown', this.pointerdown);
        gamesvg.removeEventListener('pointermove', this.pointermove);
        gamesvg.removeEventListener('pointerup', this.pointerup);
        gamesvg.removeEventListener('pointercancel', this.pointerup);
        gamesvg.removeEventListener('click', this.click);
        gamesvg.removeEventListener('wheel', this.wheel);
        gamesvg.removeEventListener('contextmenu', this.contextmenu);

        document.getElementById('room').innerHTML = "";
        document.getElementById('gameLayer').style.display = 'none';
        document.getElementById('status').innerHTML = 'Loading...';
        document.getElementById('cursors').innerHTML = "";
        document.getElementById('avatars').innerHTML = "";
    }

    #OnNewObject(object) {
        object.Client = this;
    }

    #UpdateCamera() {
        const svg = this.#Svg;
        const cam = this.#Cam;

        const roomSvg = this.Player?.Room?.Svg;
        const width = Number.parseFloat(roomSvg ? roomSvg.getAttribute('width') : 1920);
        const height = Number.parseFloat(roomSvg ? roomSvg.getAttribute('height') : 1080);
        const aspect = width / height;

        const clientBound = svg.getBoundingClientRect();
        const clientWidth = clientBound.width;
        const clientHeight = clientBound.height;
        const clientAspect = clientWidth / clientHeight;

        this.#ScreenWidth = clientWidth;
        this.#ScreenHeight = clientHeight;

        if (roomSvg) {
            const room = this.Player.Room;
            if (room !== this.#OldRoom) {
                this.Zoom = 1;
                this.Offset.x = width / 2;
                this.Offset.y = height / 2;
                this.#OldRoom = room;
            }
        }

        if (this.#DownPointers.size > 1) {
            let meanStartX = 0;
            let meanStartY = 0;
            let meanCurrentX = 0;
            let meanCurrentY = 0;
            let count = 0;
            const screenToSvg = cam.getScreenCTM().inverse();
            for (const data of this.#DownPointers.values()) {
                meanStartX += data.startX;
                meanStartY += data.startY;
                var p = svg.createSVGPoint();
                p.x = data.currentX;
                p.y = data.currentY;
                const svgPos = p.matrixTransform(screenToSvg);
                meanCurrentX += svgPos.x;
                meanCurrentY += svgPos.y;
                count += 1;
            }
            meanStartX /= count;
            meanStartY /= count;
            meanCurrentX /= count;
            meanCurrentY /= count;
            let meanStartLength = 0;
            let meanCurrentLength = 0;
            for (const data of this.#DownPointers.values()) {
                let dx = data.startX - meanStartX;
                let dy = data.startY - meanStartY;
                meanStartLength += Math.sqrt(dx * dx + dy * dy);
                var p = svg.createSVGPoint();
                p.x = data.currentX;
                p.y = data.currentY;
                const svgPos = p.matrixTransform(screenToSvg);
                dx = svgPos.x - meanCurrentX;
                dy = svgPos.y - meanCurrentY;
                meanCurrentLength += Math.sqrt(dx * dx + dy * dy);
            }
            meanStartLength /= count;
            meanCurrentLength /= count;
            const scale = meanCurrentLength / meanStartLength;
            this.Zoom *= scale;
            this.Offset.x -= meanCurrentX - meanStartX;
            this.Offset.y -= meanCurrentY - meanStartY;
        }

        this.Zoom = Math.min(Math.max(this.Zoom, minZoom), maxZoom);

        let camWidth = width / this.Zoom;
        let camHeight = height / this.Zoom;
        if (clientAspect < aspect) {
            // Client is taller.
            camHeight = camWidth / clientAspect;
        }
        else {
            // Client is wider.
            camWidth = camHeight * clientAspect;
        }

        const camMid = this.Offset;

//        const halfWidth = width / 2;
//        const halfCamWidth = camWidth / 2;
//        if (camWidth < width) {
//            let camMinX = camMid.x - halfCamWidth;
//            let camMaxX = camMid.x + halfCamWidth;
//            if (camMinX < 0) {
//                camMid.x = halfCamWidth;
//            }
//            if (camMaxX > width) {
//                camMid.x = width - halfCamWidth;
//            }
//        }
//        else {
//            camMid.x = halfWidth;
//        }
//
//        const halfHeight = height / 2;
//        const halfCamHeight = camHeight / 2;
//        if (camHeight < height) {
//            let camMinY = camMid.y - halfCamHeight;
//            let camMaxY = camMid.y + halfCamHeight;
//            if (camMinY < 0) {
//                camMid.y = halfCamHeight;
//            }
//            if (camMaxY > height) {
//                camMid.y = height - halfCamHeight;
//            }
//        }
//        else {
//            camMid.y = halfHeight;
//        }

        camMid.x = Math.min(Math.max(camMid.x, 0), width);
        camMid.y = Math.min(Math.max(camMid.y, 0), height);

        let scale = clientWidth / camWidth;
        if (isNaN(scale)) {
            scale = 1;
        }
        const matrix = svg.createSVGMatrix();
        matrix.a = scale;
        matrix.d = scale;
        matrix.e = clientWidth / 2 - scale * camMid.x;
        matrix.f = clientHeight / 2 - scale * camMid.y;

        const transform = cam.transform.baseVal;
        transform.getItem(0).setMatrix(matrix);
    }
}
