export class float2 {
    #x = 0;
    #y = 0;

    get x() {
        return this.#x;
    }

    set x(value) {
        this.#x = value;
    }

    get y() {
        return this.#y;
    }

    set y(value) {
        this.#y = value;
    }

    constructor(x = 0, y = 0) {
        this.#x = x;
        this.#y = y;
    }

    toString() {
        return `${this.x} ${this.y}`;
    }

    ToSimple() {
        return { x: this.x, y: this.y };
    }

    Clone() {
        return new float2().Copy(this);
    }

    Copy(other) {
        this.x = other.x;
        this.y = other.y;
        return this;
    }

    Add(other) {
        this.x += other.x;
        this.y += other.y;
        return this;
    }

    Subtract(other) {
        this.x -= other.x;
        this.y -= other.y;
        return this;
    }

    Multiply(other) {
        this.x *= other.x;
        this.y *= other.y;
        return this;
    }

    MultiplyScalar(value) {
        this.x *= value;
        this.y *= value;
        return this;
    }

    Multiply2x3(mat) {
        const x = this.#x;
        const y = this.#y;
        const m00 = mat.Get(0, 0);
        const m01 = mat.Get(0, 1);
        const m02 = mat.Get(0, 2);
        const m10 = mat.Get(1, 0);
        const m11 = mat.Get(1, 1);
        const m12 = mat.Get(1, 2);
        this.#x = m00 * x + m01 * y + m02;
        this.#y = m10 * x + m11 * y + m12;
        return this;
    }

    Dot(other) {
        return this.x * other.x + this.y * other.y;
    }

    Cross(other) {
        return this.x * other.y - this.y * other.x;
    }

    Perpendicular() {
        const x = this.#x;
        const y = this.#y;
        this.#x = -y;
        this.#y = x;
        return this;
    }

    LengthSqr() {
        return this.Dot(this);
    }

    Length() {
        return Math.sqrt(this.LengthSqr());
    }

    Interpolate(v0, v1, t, result) {
        result = result ?? this;
        const x0 = v0.x;
        const y0 = v0.y;
        const x1 = v1.x;
        const y1 = v1.y;
        result.x = x0 * (1 - t) + x1 * t;
        result.y = y0 * (1 - t) + y1 * t;
        return result;
    }
}

