import { Application } from "../Engine/Application.js";
import { SimpescClient } from "./SimpescClient.js";
import { SimpescServer } from "./SimpescServer.js";

export class Simpesc extends Application {
    constructor() {
        super();
    }

    CreateClient(connection) {
        return new SimpescClient(connection);
    }

    CreateServer(id) {
        return new SimpescServer(id);
    }

    Update(time, dt) {
        super.Update(time, dt);
    }
}
