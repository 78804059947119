import { float2 } from "../Engine/float2.js";
import { SyncObject } from "../Engine/SyncObject.js";
import { Button } from "./Button.js";
import { Element } from "./Element.js";
import { Pin } from "./Pin.js";
import { Room } from "./Room.js";
import { Sound } from "./Sound.js";

const hints = [
    "To get out of this room you'll need two players working together.",
    "Each player should turn one door handle each.",
    "Click on the open door to enter the next room.",
    "Does a sea-horse need horse-shoes?",
    "Put the glass jars on the shelf to form a chain of words.",
    "The correct jar order is: Sea - Horse - Shoe - Tree - House - Cat - Fish - Hook",
    "Working together can give you a real lift",
    "Use the red handles to lift the disc out of the glass case.",
    "When faced with a problem sometimes it helps to look at it from a different perspective.",
    "Don't forget that you can zoom in and move the camera.",
    "Zoom in close to the glass circles on the desk and line them up so that they overlap perfectly. Someone else move the disks.",
    "When aligned correctly the glass circles read: 6413",
    "Where do you normally put glasses? (You have a presence on the screen)",
    "Drag the glasses onto your player avatar at the top left of the screen.",
    "Use the glasses to read the safe keypad.",
    "Do the glitches in the flashlight beam remind you of anything?",
    "One player should make the flashlight beam as big as possible and another player should zoom in on the glitches.",
    "Some of the bolts on the metal door are dials, make them match the pattern in the flashlight beam.",
    "You escaped, congratulations!"
];

export class Room00 extends Room {
    #Door = null;
    #Exit = null;
    #Handle1 = null;
    #Handle2 = null;
    #Open1 = false;
    #Open2 = false;
    #DoorVisible = null;
    #HintText = [
        "Escaper Club v0.1 BETA",
        "",
        "This is a work in progress, sorry if you encounter bugs.",
        "",
        "Escaper Club works best in Chrome browser. It does not work in Firefox yet.",
        "",
        "",
        "Rules:",
        "",
        "1. Talk about Escaper Club.",
        "",
        "2. Seriously, tell everyone about Escaper Club.",
        "",
        "3. Some puzzles need teamwork. Others are easier as a team.",
        "",
        "4. Each player can use only one object at a time.",
        "",
        "5. There are no decoys. If you can use an object then it's part of a puzzle.",
        "",
        "6. Each object has one purpose. If you have used an object, then you don't need",
        "   it again.",
        "",
        "7. If you get stuck, hold both buttons below to get a hint.",
        ""];
    #HintTextPrevious = [null];
    #HintButtons = [];
    #HintIndex = 0;
    #HintAvailable = true;

    get HintText() {
        return [...this.#HintText];
    }

    set HintTextNext(value) {
        this._SendSetProperty('HintText', value);
    }

    get Handle1() {
        if (!this.#Handle1) {
            this.#Handle1 = this.FindDescendent('handle1');
        }
        return this.#Handle1;
    }

    get Handle2() {
        if (!this.#Handle2) {
            this.#Handle2 = this.FindDescendent('handle2');
        }
        return this.#Handle2;
    }

    get Door() {
        if (!this.#Door) {
            this.#Door = this.FindDescendent('door');
        }
        return this.#Door;
    }

    Update(time, dt) {
        super.Update(time, dt);

        const rotClosed = 0;
        const rotOpen = -0.15 * Math.PI * 2;
        const amount1 = (this.Handle1?.Rotation - rotClosed) / (rotOpen - rotClosed);
        const amount2 = (this.Handle2?.Rotation - rotClosed) / (rotOpen - rotClosed);
        const open1 = amount1 > 0.9;
        const open2 = amount2 > 0.9;
        if (!this.Client) {
            // Server update.
            if (open1 && open2 && this.Door) {
                this.Door.VisibleNext = false;
            }

            // Update hints.
            const hintButton0Held = this.#HintButtons?.[0]?.Held;
            const hintButton1Held = this.#HintButtons?.[1]?.Held;
            const bothHintButtonsHeld = hintButton0Held && hintButton1Held;
            const showHint = bothHintButtonsHeld && this.#HintAvailable;
            if (showHint) {
                this.#HintAvailable = false; // Debounce the hint buttons.
                const hint = hints[this.#HintIndex];
                const hintText = this.HintText;
                hintText.push("");
                let remaining = hint;
                while (remaining.length > 0) {
                    let line = remaining;
                    let lineEnd = remaining.length;
                    let lineStart = lineEnd;
                    if (lineEnd > 80) {
                        let spaceBreak = remaining.lastIndexOf(' ', 80);
                        if (spaceBreak > 70) {
                            lineEnd = spaceBreak;
                            lineStart = spaceBreak + 1;
                        }
                    }
                    line = remaining.substring(0, lineEnd);
                    remaining = remaining.substring(lineStart);
                    hintText.push(line);
                }
                while (hintText.length > 24) {
                    hintText.splice(0, 1);
                }
                this.HintTextNext = hintText;
                this.#HintIndex = (this.#HintIndex + 1) % hints.length;
            }
            const neitherHintButtonHeld = !hintButton0Held && !hintButton1Held;
            if (neitherHintButtonHeld) {
                this.#HintAvailable = true; // Debounce the hint buttons.
            }
        }
        else {
            if (this.Door?.Visible) {
                if (!this.#Open1) {
                    if (open1) {
                        const click = this.FindDescendent('click');
                        if (click) {
                            click.Play();
                        }
                        this.#Open1 = true;
                    }
                }
                else if (amount1 < 0.1) {
                    const click = this.FindDescendent('clunk');
                    if (click) {
                        click.Play();
                    }
                    this.#Open1 = false;
                }
                if (!this.#Open2) {
                    if (open2) {
                        const click = this.FindDescendent('click');
                        if (click) {
                            click.Play();
                        }
                        this.#Open2 = true;
                    }
                }
                else if (amount2 < 0.1) {
                    const click = this.FindDescendent('clunk');
                    if (click) {
                        click.Play();
                    }
                    this.#Open2 = false;
                }
            }
            if (this.Door && this.Door.Visible !== this.#DoorVisible) {
                if (!this.Door.Visible && this.#DoorVisible) {
                    const click = this.FindDescendent('open');
                    if (click) {
                        click.Play();
                    }
                }
                this.#DoorVisible = this.Door?.Visible;
            }

            const maxLines = 24;
            let hintChanged = false;
            for (let i = 0; i < this.#HintText.length && !hintChanged && i < maxLines; ++i) {
                hintChanged |= this.#HintText[i] !== this.#HintTextPrevious[i];
            }
            if (hintChanged) {
                const hintText = document.getElementById('hintText');
                if (hintText) {
                    const spans = hintText.getElementsByTagName('tspan');
                    let i = 0;
                    for (const span of spans) {
                        span.innerHTML = this.#HintText[i++];
                        if (i >= maxLines) {
                            break;
                        }
                    }
                    spans[24].innerHTML = ">";
                    for (let i = 0; i < this.#HintText.length && i < maxLines; ++i) {
                        this.#HintTextPrevious[i] = this.#HintText[i];
                    }
                }
            }

            for (let i = 0; i < 2; ++i) {
                if (!this.#HintButtons[i]) {
                    this.#HintButtons[i] = this.FindDescendent(`buttonHint${i}`);
                }
                if (this.#HintButtons[i]?.Pressed) {
                    this._PlaySound('keydown');
                }
                if (this.#HintButtons[i]?.Released) {
                    this._PlaySound('keyup');
                }
            }
        }

        if (!this.#Exit) {
            this.#Exit = this.FindDescendent('exit');
            if (this.#Exit) {
                if (this.Client) {
                    this.#Exit.OnClick.add(this.#ClientExitOnClick.bind(this));
                }
                else {
                    this.#Exit.OnClick.add(this.#ServerExitOnClick.bind(this));
                }
            }
        }
        else {

        }
    }

    _LoadedRoom() {
        super._LoadedRoom();

        if (!this.Client) {
            const svg = this.Svg;

            // Room exit.
            const exit = new Element();
            exit.NameNext = 'exit';
            exit.RoomNext = this;
            exit.ElementIdNext = 'exit';
            this.AddChildNext(exit);

            // Room door.
            const door = new Element();
            door.NameNext = 'door';
            door.RoomNext = this;
            door.ElementIdNext = 'door';
            this.AddChildNext(door);

            // Sounds.
            const doorclick = new Sound();
            doorclick.SourceNext = '/static/snd/doorunlock.mp3';
            doorclick.NameNext = 'click';
            this.AddChildNext(doorclick);
            const doorclunk = new Sound();
            doorclunk.SourceNext = '/static/snd/doorlock.mp3';
            doorclunk.NameNext = 'clunk';
            this.AddChildNext(doorclunk);
            const dooropen = new Sound();
            dooropen.SourceNext = '/static/snd/dooropen.mp3';
            dooropen.NameNext = 'open';
            this.AddChildNext(dooropen);

            for (let i = 1; i < 3; ++i) {
                const groupId = `handle${i}`;
                const groupElement = svg.getElementById(groupId);
                const collisionElement = groupElement.getElementsByTagName('rect')[0];
                const body = this._Body(groupElement, [collisionElement]);

                const elementToWorld = this._GetElementTransform(groupElement);
                const elementTransform = this.DecomposeMatrix(elementToWorld);
                const constraintElements = [groupElement.getElementsByTagName('ellipse')[0]];
                for (const ce of constraintElements) {
                    const cxString = ce.getAttribute('cx');
                    const cyString = ce.getAttribute('cy');
                    const cx = Number.parseFloat(cxString);
                    const cy = Number.parseFloat(cyString);
                    const parentToWorld = this._GetElementTransform(ce);
                    const objectToParent = new DOMMatrix([1, 0, 0, 1, cx, cy]);
                    const objectToWorld = parentToWorld.multiply(objectToParent);
                    const x = objectToWorld.e;
                    const y = objectToWorld.f;
                    const worldPinPosition = new float2(x, y);
                    const bodyPinPosition = worldPinPosition.Clone().Subtract(elementTransform.translate);
                    const pin = new Pin();
                    const rotMin = -0.15 * Math.PI * 2;
                    const rotMax = 0;
                    const rotSpeed = 1 * Math.PI * 2;
                    const rotTorque = 0.01;
                    pin.InitialiseNext(this, body, bodyPinPosition, null, worldPinPosition, rotMin, rotMax, rotSpeed, rotTorque);
                    body.AddChildNext(pin);
                }
            }

            // Hint buttons.
            for (let i = 0; i < 2; ++i) {
                const id = `buttonHint${i}`;
                const button = new Button();
                button.ElementIdNext = id;
                button.RoomNext = this;
                this.AddChildNext(button);
                this.#HintButtons.push(button);
            }

            // Sounds.
            this._AddSound('keydown', '/static/snd/keydown.mp3');
            this._AddSound('keyup', '/static/snd/keyup.mp3');
        }
    }

    _ReceiveSetProperty(name, value) {
        switch (name) {
            case 'HintText':
                this.#HintText = value;
                break;
            default:
                super._ReceiveSetProperty(name, value);
                break;
        }
    }

    #ClientExitOnClick(event) {
        const door = this.FindDescendent('door');
        if (door && !door.Visible) {
            const player = this.Client.Player;
            player.ClickedNext = this.#Exit;
        }
    }

    #ServerExitOnClick(target, player) {
        this._OnExit(target, player, this);
    }
}

SyncObject.RegisterType(Room00, 'Room00');
