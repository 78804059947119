import { Child } from "./Child.js";

export class Parent extends Child {
    #Children = new Set();

    constructor() {
        super();
    }

    get Children() {
        return Array.from(this.#Children);
    }

    AddChildNext(value) {
        this._SendMessage({
            type: "addchild",
            value
        });
    }

    RemoveChildNext(value) {
        this._SendMessage({
            type: "removechild",
            value
        });
    }

    FindDescendent(name) {
        if (this.Name === name) {
            return this;
        }
        for (const child of this.#Children) {
            const result = child.FindDescendent(name);
            if (result) {
                return result;
            }
        }
        return null;
    }

    Update(time, dt) {
        super.Update(time, dt);
        for (const child of this.#Children) {
            child.Update(time, dt);
        }
    }

    _ReceiveMessage(message) {
        switch (message.type) {
            case "addchild":
                {
                    const child = message.value;
                    if (!this.#Children.has(child)) {
                        this.#Children.add(child);
                        child._AddParent(this);
                    }
                }
                break;
            case "removechild":
                {
                    const child = message.value;
                    if (this.#Children.has(child)) {
                        this.#Children.delete(child);
                        child._RemoveParent(this);
                    }
                }
                break;
            default:
                super._ReceiveMessage(message);
                break;
        }
    }

    _ClassifyMessages(messageMap) {
        super._ClassifyMessages(messageMap);

        const lastChild = new WeakMap();

        for (const message of messageMap.keys()) {
            switch (message.type) {
                case 'addchild':
                case 'removechild':
                    const child = message.value;
                    if (lastChild.has(child)) {
                        messageMap.set(message, false);
                    }
                    lastChild.set(child, message);
                    messageMap.set(message, true);
                    break;
                default:
                    break;
            }
        }

    }
}